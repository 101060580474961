import React, { useState } from 'react';
import PlatformHub from './PlatformHub';
import DimensionsHub from './DimensionsHub';
import BudgetHub from './BudgetHub';
import PresetSelection from './PresetSelection';
import { BASE_URL } from '../utils/constants';

const blankConfig = {
  platform_config: {},
  dimensions: {},
  budget: {
    total_daily: 10000,
    distribution: {},
  },
  seed: 1,
  startDate: '2024-01-01',
  days: 90,
  dynamic_allocation: true,
};

const ConfigController = ({ setDataGenerated }) => {
  const [showPresetSelection, setShowPresetSelection] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [config, setConfig] = useState(blankConfig);

  const startSimulation = async simulationConfig => {
    setIsLoading(true);
    setDataGenerated(false);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL}/generate_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Origin: 'http://localhost:3000',
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify(simulationConfig),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setDataGenerated(true);
    } catch (err) {
      setError('Failed to start simulation: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    { number: 1, label: 'Platform' },
    { number: 2, label: 'Dimensions' },
    { number: 3, label: 'Budget' },
  ];

  const isPlatformConfigured = Object.keys(config.platform_config).length > 0;

  const isConfigComplete = () => {
    return steps.every(step => isStepComplete(step));
  };

  const isStepComplete = step => {
    switch (step.number) {
      case 1:
        return isPlatformConfigured;
      case 2:
        return true;
      case 3:
        return (
          config.budget.total_daily > 0 &&
          Object.values(config.budget.distribution).reduce((a, b) => a + b, 0) > 0
        );
      default:
        return false;
    }
  };

  const StepIndicator = ({ steps, currentStep, setCurrentStep }) => (
    <div className="nav-bar">
      <div className="nav-button-wrapper">
        {currentStep !== 1 && (
          <button className="nav-button" onClick={() => setCurrentStep(currentStep - 1)}>
            &lt;
          </button>
        )}
      </div>
      {steps.map(step => (
        <div
          key={step.number}
          className={`nav-item ${currentStep === step.number ? 'active' : ''}`}
          onClick={() => {
            if (step.number === 1 || (step.number > 1 && isPlatformConfigured)) {
              setCurrentStep(step.number);
            }
          }}
          style={{
            cursor:
              step.number === 1 || (step.number > 1 && isPlatformConfigured)
                ? 'pointer'
                : 'not-allowed',
          }}
        >
          <span style={{ whiteSpace: 'nowrap' }}>{step.label}</span>
        </div>
      ))}
      <div className="nav-button-wrapper">
        {currentStep !== steps.length && (
          <button
            className="nav-button"
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={currentStep === 1 && !isPlatformConfigured}
          >
            &gt;
          </button>
        )}
      </div>
    </div>
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <PlatformHub config={config} setConfig={setConfig} />;
      case 2:
        return <DimensionsHub config={config} setConfig={setConfig} />;
      case 3:
        return <BudgetHub config={config} setConfig={setConfig} />;
      default:
        return null;
    }
  };

  const onSimulationComplete = () => {
    setCurrentStep(3);
    setShowPresetSelection(false);
  };

  return (
    <div className="container">
      {showPresetSelection ? (
        <PresetSelection
          startSimulation={startSimulation}
          onSimulationComplete={onSimulationComplete}
          setConfig={setConfig}
          blankConfig={blankConfig}
          setShowPresetSelection={setShowPresetSelection}
        />
      ) : (
        <>
          <StepIndicator
            steps={steps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            config={config}
          />
          <div className="action-buttons">
            <button
              className="button button-secondary"
              onClick={() => {
                setCurrentStep(1);
                setShowPresetSelection(true);
                setError(null);
              }}
            >
              Restart
            </button>
            <button
              className={`button button-primary ${isLoading || !isConfigComplete() ? 'disabled' : ''}`}
              onClick={() => startSimulation(config)}
              disabled={isLoading || !isConfigComplete()}
            >
              {isLoading ? 'Starting...' : 'Start'}
            </button>
          </div>
          {error && <div className="simulation-error">{error}</div>}
          <div className="card">{renderCurrentStep()}</div>
        </>
      )}
    </div>
  );
};

export default ConfigController;


import React, { useState } from 'react';
import './ControllerStyles.css';
import PercentageEditorGroup from './atoms/PercentageEditorGroup';
import { DIMENSIONS } from '../utils/dimensionConfig';

const DIMENSION_SPLITS = Object.fromEntries(
  Object.entries(DIMENSIONS).filter(([key]) => key !== 'platform')
);

const DimensionsHub = ({ config, setConfig }) => {
  const [showSplitConigureModal, setShowSplitConigureModal] = useState(false);
  const [selectedDimensionInfo, setSelectedDimension] = useState(null);

  const handleAddDimension = (dimensionId, dimensionData) => {
    setSelectedDimension({ id: dimensionId, ...dimensionData });
    setShowSplitConigureModal(true);
  };

  const handleSaveDimensionConfig = (dimensionId, dimensionConfig) => {
    setConfig(prev => ({
      ...prev,
      dimensions: {
        ...prev.dimensions,
        [dimensionId]: dimensionConfig,
      },
    }));
    setShowSplitConigureModal(false);
  };

  const renderPlatformSplits = dimensionId => {
    const dimensionData = config.dimensions[dimensionId];
    return Object.entries(config.platform_config).map(([platformName, _]) => (
      <div key={`${dimensionId}-${platformName}`} className="platform-section">
        <h4>{platformName}</h4>
        <table>
          <tbody>
            {Object.entries(dimensionData[platformName] || {}).map(([segment, value]) => (
              <tr key={segment}>
                <td>
                  <strong>{segment}:</strong>
                </td>
                <td>{value}%</td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr className="platform-divider" />
      </div>
    ));
  };

  return (
    <div className="container">
      <h3>Dimension Management</h3>
      {showSplitConigureModal && selectedDimensionInfo ? (
        <div className="modal">
          <DimensionConfig
            selectedDimensionInfo={selectedDimensionInfo}
            platforms={Object.keys(config.platform_config)}
            segmentWeightsByPlatform={
              config.dimensions[selectedDimensionInfo.id] ||
              DIMENSIONS[selectedDimensionInfo.id].segments.reduce((acc, segment) => {
                DIMENSIONS.platform.segments
                  .map(x => x.id)
                  .forEach(platform => {
                    if (!acc[platform]) acc[platform] = {};
                    acc[platform][segment.id] = segment.weightsByPlatform[platform];
                  });
                return acc;
              }, {})
            }
            onSave={config => handleSaveDimensionConfig(selectedDimensionInfo.id, config)}
            onClose={() => setShowSplitConigureModal(false)}
          />
        </div>
      ) : (
        <>
          <div>
            <h3>Add Dimension</h3>
            <div className="config-buttons">
              {Object.entries(DIMENSION_SPLITS).map(([dimensionId, dimensionData]) => (
                <button
                  key={dimensionId}
                  className={`button ${config.dimensions[dimensionId] ? 'button-secondary' : 'button-primary'}`}
                  onClick={() => handleAddDimension(dimensionId, dimensionData)}
                  disabled={config.dimensions[dimensionId]}
                >
                  {dimensionData.name}
                  {config.dimensions[dimensionId] && <span> ✓</span>}
                </button>
              ))}
            </div>
          </div>
          <div>
            <h3>Active Dimensions</h3>
            {Object.keys(config.dimensions).length === 0 ? (
              <p className="text-secondary">No dimensions configured yet</p>
            ) : (
              <div className="dimension-list">
                {Object.keys(config.dimensions).map(dimensionId => (
                  <div key={dimensionId} className="card">
                    <div className="dimension-header">
                      <h4>{DIMENSION_SPLITS[dimensionId].name}</h4>
                      <button
                        className="button button-secondary"
                        onClick={() =>
                          handleAddDimension(dimensionId, DIMENSION_SPLITS[dimensionId])
                        }
                      >
                        Edit
                      </button>
                    </div>
                    <div className="segments-distribution">{renderPlatformSplits(dimensionId)}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const DimensionConfig = ({
  selectedDimensionInfo,
  platforms,
  segmentWeightsByPlatform = {},
  onSave,
  onClose,
}) => {
  const initializedWeights = platforms.reduce((acc, platform) => {
    if (!segmentWeightsByPlatform[platform]) {
      acc[platform] = {};
    } else {
      acc[platform] = segmentWeightsByPlatform[platform];
    }
    return acc;
  }, {});
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <h3>Configure {selectedDimensionInfo.name}</h3>
      <PercentageEditorGroup
        items={selectedDimensionInfo.segments}
        groupedByWeights={initializedWeights}
        onSave={updatedWeigths => {
          onSave(updatedWeigths);
        }}
        onCancel={onClose}
      />
    </div>
  );
};

export default DimensionsHub;

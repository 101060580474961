export const mockData = {
  fetch_metric_tree: {
    absolute_diff: -3.5956114597418205,
    base_value: 8.107709296461634,
    children: {
      'CPM/(CPM = Spend \u00f7 Impressions)': [
        {
          absolute_diff: 982870.0,
          base_value: 1233394.0,
          children: {
            '(CPM = Spend \u00f7 Impressions) -> Impressions/Age Group': [
              {
                absolute_diff: 220463.0,
                base_value: 243710.0,
                contribution: 0.22430535065675014,
                header: 'Impressions, Age Group = 35-44:',
                id: '705e5c7006422dd6',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'age_group',
                    filter: "age_group == '35-44'",
                    segment_value: '35-44',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.9046120388986911,
                split_key_headers: {},
              },
              {
                absolute_diff: 212880.0,
                base_value: 241646.0,
                contribution: 0.2165901899539105,
                header: 'Impressions, Age Group = 45-54:',
                id: '4c4e4abde98ad6b9',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'age_group',
                    filter: "age_group == '45-54'",
                    segment_value: '45-54',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.8809580957268071,
                split_key_headers: {},
              },
              {
                absolute_diff: 206639.0,
                base_value: 247232.0,
                contribution: 0.2102404183666202,
                header: 'Impressions, Age Group = 55+:',
                id: '5e5481dbaee35584',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'age_group',
                    filter: "age_group == '55+'",
                    segment_value: '55+',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.8358100893088274,
                split_key_headers: {},
              },
              {
                absolute_diff: 177996.0,
                base_value: 259184.0,
                contribution: 0.18109821237803575,
                header: 'Impressions, Age Group = 25-34:',
                id: '379ac8546ad6fddf',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'age_group',
                    filter: "age_group == '25-34'",
                    segment_value: '25-34',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.6867553552688437,
                split_key_headers: {},
              },
              {
                absolute_diff: 164892.0,
                base_value: 241622.0,
                contribution: 0.16776582864468342,
                header: 'Impressions, Age Group = 18-24:',
                id: '7471ff31424965fc',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'age_group',
                    filter: "age_group == '18-24'",
                    segment_value: '18-24',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.6824378574798653,
                split_key_headers: {},
              },
            ],
            '(CPM = Spend \u00f7 Impressions) -> Impressions/Country': [
              {
                absolute_diff: 154484.0,
                base_value: 159112.0,
                contribution: 0.15717643228504277,
                header: 'Impressions, Country = FR:',
                id: '558916c03f94521a',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'country',
                    filter: "country == 'FR'",
                    segment_value: 'FR',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.9709135703152496,
                split_key_headers: {},
              },
              {
                absolute_diff: 128836.0,
                base_value: 149486.0,
                contribution: 0.13108142480694293,
                header: 'Impressions, Country = KR:',
                id: '5a379c57da8f618c',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'country',
                    filter: "country == 'KR'",
                    segment_value: 'KR',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.8618599735092249,
                split_key_headers: {},
              },
              {
                absolute_diff: 125760.0,
                base_value: 150061.0,
                contribution: 0.12795181458382085,
                header: 'Impressions, Country = UK:',
                id: 'f666f88ccd5d844',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'country',
                    filter: "country == 'UK'",
                    segment_value: 'UK',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.8380591892630331,
                split_key_headers: {},
              },
              {
                absolute_diff: 118338.0,
                base_value: 160050.0,
                contribution: 0.12040045987770509,
                header: 'Impressions, Country = DE:',
                id: '4d4c1ef1e60d9d4b',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'country',
                    filter: "country == 'DE'",
                    segment_value: 'DE',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.7393814432989692,
                split_key_headers: {},
              },
              {
                absolute_diff: 116715.0,
                base_value: 161131.0,
                contribution: 0.11874917333930225,
                header: 'Impressions, Country = BR:',
                id: '1609772779f7e5b0',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'country',
                    filter: "country == 'BR'",
                    segment_value: 'BR',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.7243485114596198,
                split_key_headers: {},
              },
              {
                absolute_diff: 116609.0,
                base_value: 156558.0,
                contribution: 0.11864132591288777,
                header: 'Impressions, Country = JP:',
                id: '22908a2335faa91f',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'country',
                    filter: "country == 'JP'",
                    segment_value: 'JP',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.7448293923018945,
                split_key_headers: {},
              },
              {
                absolute_diff: 114418.0,
                base_value: 135363.0,
                contribution: 0.11641213995747149,
                header: 'Impressions, Country = US:',
                id: '2701b863f6ef3bf',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'country',
                    filter: "country == 'US'",
                    segment_value: 'US',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.8452679092514201,
                split_key_headers: {},
              },
              {
                absolute_diff: 107710.0,
                base_value: 161633.0,
                contribution: 0.10958722923682684,
                header: 'Impressions, Country = IN:',
                id: '6dad86c5489d9d7',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'country',
                    filter: "country == 'IN'",
                    segment_value: 'IN',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.6663861958882158,
                split_key_headers: {},
              },
            ],
            '(CPM = Spend \u00f7 Impressions) -> Impressions/Gender': [
              {
                absolute_diff: 501585.0,
                base_value: 614179.0,
                children: {
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Gender = F/Age Group': [
                    {
                      absolute_diff: 123045.0,
                      base_value: 115851.0,
                      contribution: 0.24531235981937258,
                      header: 'Impressions, Gender = F & Age Group = 35-44:',
                      id: '6c41cef811bdef57',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '35-44'",
                          segment_value: '35-44',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 1.062097003910195,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 117203.0,
                      base_value: 113386.0,
                      contribution: 0.23366528105904283,
                      header: 'Impressions, Gender = F & Age Group = 45-54:',
                      id: '46aefbb8bcf243c',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '45-54'",
                          segment_value: '45-54',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 1.033663768013688,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 97589.0,
                      base_value: 127860.0,
                      contribution: 0.19456124086645335,
                      header: 'Impressions, Gender = F & Age Group = 25-34:',
                      id: '65e87fc5717ce7fd',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '25-34'",
                          segment_value: '25-34',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.7632488659471297,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 94385.0,
                      base_value: 138255.0,
                      contribution: 0.18817349003658404,
                      header: 'Impressions, Gender = F & Age Group = 55+:',
                      id: '2071ea1c7a9cc374',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '55+'",
                          segment_value: '55+',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.6826877870601424,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 69363.0,
                      base_value: 118827.0,
                      contribution: 0.1382876282185472,
                      header: 'Impressions, Gender = F & Age Group = 18-24:',
                      id: '153a4dc384bde8a6',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '18-24'",
                          segment_value: '18-24',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.5837309702340376,
                      split_key_headers: {},
                    },
                  ],
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Gender = F/Country': [
                    {
                      absolute_diff: 84318.0,
                      base_value: 80280.0,
                      contribution: 0.16810311313137355,
                      header: 'Impressions, Gender = F & Country = FR:',
                      id: '12ed122a17b145de',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'FR'",
                          segment_value: 'FR',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 1.0502989536621823,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 73751.0,
                      base_value: 73913.0,
                      contribution: 0.1470358962090174,
                      header: 'Impressions, Gender = F & Country = UK:',
                      id: '59f7f2aed52b587a',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'UK'",
                          segment_value: 'UK',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.9978082340048435,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 67394.0,
                      base_value: 79285.0,
                      contribution: 0.13436207223102764,
                      header: 'Impressions, Gender = F & Country = DE:',
                      id: '283b4baa9d83bf22',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'DE'",
                          segment_value: 'DE',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.8500220722709213,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 63409.0,
                      base_value: 78057.0,
                      contribution: 0.12641725729437683,
                      header: 'Impressions, Gender = F & Country = BR:',
                      id: '5409938bd3a760e1',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'BR'",
                          segment_value: 'BR',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.8123422627054588,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 60177.0,
                      base_value: 71719.0,
                      contribution: 0.11997368342354736,
                      header: 'Impressions, Gender = F & Country = KR:',
                      id: '3cc51ccbc2dd9ee4',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'KR'",
                          segment_value: 'KR',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.8390663561957084,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 55644.0,
                      base_value: 78625.0,
                      contribution: 0.1109363318281049,
                      header: 'Impressions, Gender = F & Country = JP:',
                      id: '7efb0b4ac593e328',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'JP'",
                          segment_value: 'JP',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.7077138314785374,
                      split_key_headers: {},
                    },
                  ],
                },
                contribution: 0.510326899793462,
                header: 'Impressions, Gender = F:',
                id: '33aacd606d49dc39',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'gender',
                    filter: "gender == 'F'",
                    segment_value: 'F',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.8166755945742201,
                split_key_headers: {
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Gender = F/Age Group':
                    'By Age Group, (CPM = Spend \u00f7 Impressions) -> Impressions & Gender = F:',
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Gender = F/Country':
                    'By Country, (CPM = Spend \u00f7 Impressions) -> Impressions & Gender = F:',
                },
              },
              {
                absolute_diff: 481285.0,
                base_value: 619215.0,
                contribution: 0.489673100206538,
                header: 'Impressions, Gender = M:',
                id: '6768f17757ad4a75',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'gender',
                    filter: "gender == 'M'",
                    segment_value: 'M',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.7772502281113991,
                split_key_headers: {},
              },
            ],
            '(CPM = Spend \u00f7 Impressions) -> Impressions/Platform': [
              {
                absolute_diff: 982870.0,
                base_value: 1233394.0,
                children: {
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Platform = Unity/Age Group': [
                    {
                      absolute_diff: 220463.0,
                      base_value: 243710.0,
                      contribution: 0.22430535065675014,
                      header: 'Impressions, Platform = Unity & Age Group = 35-44:',
                      id: '6c744ed66e5084e6',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '35-44'",
                          segment_value: '35-44',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.9046120388986911,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 212880.0,
                      base_value: 241646.0,
                      contribution: 0.2165901899539105,
                      header: 'Impressions, Platform = Unity & Age Group = 45-54:',
                      id: '50f054829c930425',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '45-54'",
                          segment_value: '45-54',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.8809580957268071,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 206639.0,
                      base_value: 247232.0,
                      contribution: 0.2102404183666202,
                      header: 'Impressions, Platform = Unity & Age Group = 55+:',
                      id: '15f599e89e2fa288',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '55+'",
                          segment_value: '55+',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.8358100893088274,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 177996.0,
                      base_value: 259184.0,
                      contribution: 0.18109821237803575,
                      header: 'Impressions, Platform = Unity & Age Group = 25-34:',
                      id: '5adb03858e9e875d',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '25-34'",
                          segment_value: '25-34',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.6867553552688437,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 164892.0,
                      base_value: 241622.0,
                      contribution: 0.16776582864468342,
                      header: 'Impressions, Platform = Unity & Age Group = 18-24:',
                      id: '7bfb337fbd2e1285',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'age_group',
                          filter: "age_group == '18-24'",
                          segment_value: '18-24',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.6824378574798653,
                      split_key_headers: {},
                    },
                  ],
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Platform = Unity/Country': [
                    {
                      absolute_diff: 154484.0,
                      base_value: 159112.0,
                      contribution: 0.15717643228504277,
                      header: 'Impressions, Platform = Unity & Country = FR:',
                      id: '179e858085c2f976',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'FR'",
                          segment_value: 'FR',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.9709135703152496,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 128836.0,
                      base_value: 149486.0,
                      contribution: 0.13108142480694293,
                      header: 'Impressions, Platform = Unity & Country = KR:',
                      id: '764b74604d3772a4',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'KR'",
                          segment_value: 'KR',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.8618599735092249,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 125760.0,
                      base_value: 150061.0,
                      contribution: 0.12795181458382085,
                      header: 'Impressions, Platform = Unity & Country = UK:',
                      id: '1f18c6219f8852a5',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'UK'",
                          segment_value: 'UK',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.8380591892630331,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 118338.0,
                      base_value: 160050.0,
                      contribution: 0.12040045987770509,
                      header: 'Impressions, Platform = Unity & Country = DE:',
                      id: '35ca36aff0c30441',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'DE'",
                          segment_value: 'DE',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.7393814432989692,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 116715.0,
                      base_value: 161131.0,
                      contribution: 0.11874917333930225,
                      header: 'Impressions, Platform = Unity & Country = BR:',
                      id: '12ae4697d312e219',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'BR'",
                          segment_value: 'BR',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.7243485114596198,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 116609.0,
                      base_value: 156558.0,
                      contribution: 0.11864132591288777,
                      header: 'Impressions, Platform = Unity & Country = JP:',
                      id: '19e4e15c555d2617',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'JP'",
                          segment_value: 'JP',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.7448293923018945,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 114418.0,
                      base_value: 135363.0,
                      contribution: 0.11641213995747149,
                      header: 'Impressions, Platform = Unity & Country = US:',
                      id: '77743443ed06a744',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'US'",
                          segment_value: 'US',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.8452679092514201,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 107710.0,
                      base_value: 161633.0,
                      contribution: 0.10958722923682684,
                      header: 'Impressions, Platform = Unity & Country = IN:',
                      id: '322aa291f6796369',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'country',
                          filter: "country == 'IN'",
                          segment_value: 'IN',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.6663861958882158,
                      split_key_headers: {},
                    },
                  ],
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Platform = Unity/Gender': [
                    {
                      absolute_diff: 501585.0,
                      base_value: 614179.0,
                      contribution: 0.510326899793462,
                      header: 'Impressions, Platform = Unity & Gender = F:',
                      id: '78e33a43abe373b0',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'F'",
                          segment_value: 'F',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.8166755945742201,
                      split_key_headers: {},
                    },
                    {
                      absolute_diff: 481285.0,
                      base_value: 619215.0,
                      contribution: 0.489673100206538,
                      header: 'Impressions, Platform = Unity & Gender = M:',
                      id: '7d9efadddfe546f2',
                      metric: 'impressions',
                      path: [
                        {
                          component: 'impressions',
                          formula: 'spend - impressions',
                          split_type: 'formula',
                        },
                        {
                          dimension: 'platform',
                          filter: "platform == 'Unity'",
                          segment_value: 'Unity',
                          split_type: 'segment',
                        },
                        {
                          dimension: 'gender',
                          filter: "gender == 'M'",
                          segment_value: 'M',
                          split_type: 'segment',
                        },
                      ],
                      relative_diff: 0.7772502281113991,
                      split_key_headers: {},
                    },
                  ],
                },
                contribution: 1.0,
                header: 'Impressions, Platform = Unity:',
                id: '48df8febbafbc782',
                metric: 'impressions',
                path: [
                  {
                    component: 'impressions',
                    formula: 'spend - impressions',
                    split_type: 'formula',
                  },
                  {
                    dimension: 'platform',
                    filter: "platform == 'Unity'",
                    segment_value: 'Unity',
                    split_type: 'segment',
                  },
                ],
                relative_diff: 0.7968824236213246,
                split_key_headers: {
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Platform = Unity/Age Group':
                    'By Age Group, (CPM = Spend \u00f7 Impressions) -> Impressions & Platform = Unity:',
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Platform = Unity/Country':
                    'By Country, (CPM = Spend \u00f7 Impressions) -> Impressions & Platform = Unity:',
                  '(CPM = Spend \u00f7 Impressions) -> Impressions/Platform = Unity/Gender':
                    'By Gender, (CPM = Spend \u00f7 Impressions) -> Impressions & Platform = Unity:',
                },
              },
            ],
          },
          contribution: 1.7968824236213243,
          header: 'Impressions:',
          id: '20729149a10f2aa1',
          metric: 'impressions',
          path: [
            {
              component: 'impressions',
              formula: 'spend - impressions',
              split_type: 'formula',
            },
          ],
          relative_diff: 0.7968824236213246,
          split_key_headers: {
            '(CPM = Spend \u00f7 Impressions) -> Impressions/Age Group':
              'By Age Group, (CPM = Spend \u00f7 Impressions) -> Impressions:',
            '(CPM = Spend \u00f7 Impressions) -> Impressions/Country':
              'By Country, (CPM = Spend \u00f7 Impressions) -> Impressions:',
            '(CPM = Spend \u00f7 Impressions) -> Impressions/Gender':
              'By Gender, (CPM = Spend \u00f7 Impressions) -> Impressions:',
            '(CPM = Spend \u00f7 Impressions) -> Impressions/Platform':
              'By Platform, (CPM = Spend \u00f7 Impressions) -> Impressions:',
          },
        },
      ],
      'CPM/Country': [
        {
          absolute_diff: -4.791039775650821,
          base_value: 9.816176085776325,
          contribution: 0.16397242078006113,
          header: 'CPM, Country = UK:',
          id: '5d66c374183d9b8d',
          metric: 'cpm',
          path: [
            {
              dimension: 'country',
              filter: "country == 'UK'",
              segment_value: 'UK',
              split_type: 'segment',
            },
          ],
          relative_diff: -0.48807598129714236,
          split_key_headers: {},
        },
        {
          absolute_diff: -5.230048202764954,
          base_value: 11.38732599677809,
          contribution: 0.16178538493504033,
          header: 'CPM, Country = US:',
          id: '3cb9651ad5683e48',
          metric: 'cpm',
          path: [
            {
              dimension: 'country',
              filter: "country == 'US'",
              segment_value: 'US',
              split_type: 'segment',
            },
          ],
          relative_diff: -0.4592867723506585,
          split_key_headers: {},
        },
        {
          absolute_diff: -3.9774779402961418,
          base_value: 8.611147531937172,
          contribution: 0.13837958412254484,
          header: 'CPM, Country = JP:',
          id: 'a0c01553cf17ee7',
          metric: 'cpm',
          path: [
            {
              dimension: 'country',
              filter: "country == 'JP'",
              segment_value: 'JP',
              split_type: 'segment',
            },
          ],
          relative_diff: -0.461898710426735,
          split_key_headers: {},
        },
        {
          absolute_diff: -3.6323577345939633,
          base_value: 8.638491418924545,
          contribution: 0.12899257488950772,
          header: 'CPM, Country = DE:',
          id: '4c3bfd1d6a287fe',
          metric: 'cpm',
          path: [
            {
              dimension: 'country',
              filter: "country == 'DE'",
              segment_value: 'DE',
              split_type: 'segment',
            },
          ],
          relative_diff: -0.42048519335638534,
          split_key_headers: {},
        },
        {
          absolute_diff: -3.407871353214815,
          base_value: 7.705060880308369,
          contribution: 0.12818855824764816,
          header: 'CPM, Country = FR:',
          id: '262e0fab89838c89',
          metric: 'cpm',
          path: [
            {
              dimension: 'country',
              filter: "country == 'FR'",
              segment_value: 'FR',
              split_type: 'segment',
            },
          ],
          relative_diff: -0.4422899969452839,
          split_key_headers: {},
        },
        {
          absolute_diff: -3.3841909840945643,
          base_value: 8.007146124775351,
          contribution: 0.11613495995642602,
          header: 'CPM, Country = KR:',
          id: '1b36deb2f5a46f95',
          metric: 'cpm',
          path: [
            {
              dimension: 'country',
              filter: "country == 'KR'",
              segment_value: 'KR',
              split_type: 'segment',
            },
          ],
          relative_diff: -0.42264633757879766,
          split_key_headers: {},
        },
      ],
    },
    contribution: 1,
    header: 'CPM:',
    id: '0',
    metric: 'cpm',
    path: [],
    relative_diff: -0.44348056007768033,
    split_key_headers: {
      'CPM/(CPM = Spend \u00f7 Impressions)': 'By (CPM = Spend \u00f7 Impressions), CPM:',
      'CPM/Country': 'By Country, CPM:',
    },
  },
  fetch_metric_report: {
    key_nodes: {
      0: {
        highlight_reason: 'CPM decreased significantly by 44.35%',
        priority: 'High',
      },
      '179e858085c2f976': {
        highlight_reason: 'France Unity performance leads platform growth',
        priority: 'High',
      },
      '20729149a10f2aa1': {
        highlight_reason: 'Impressions growth drives overall efficiency gains',
        priority: 'High',
      },
      '5d66c374183d9b8d': {
        highlight_reason: 'UK CPM reduction leads country improvements',
        priority: 'High',
      },
      '6c41cef811bdef57': {
        highlight_reason: 'Female 35-44 segment shows strongest growth',
        priority: 'High',
      },
    },
    report:
      '# Metric Tree Analysis\n\n## 1. Performance Summary\n\n### Overall Results\n* CPM decreased significantly from **8.11** to **4.51** (**-44.35%**)\n* Impressions grew substantially to **2.22M** (**+79.69%**)\n* Efficiency gains seen across all major segments\n\n### Key Contributors\n* Female audience drove **+91.70%** of improvement\n* Unity platform contributed **+79.69%** to growth\n* Age 35-44 segment led with **+90.46%** increase\n\n## 2. Metric Analysis\n\n### Cost Efficiency\n* UK CPM reduced from **9.82** to **5.03** (**-48.81%**)\n* US CPM decreased from **11.39** to **6.16** (**-45.93%**)\n* Japan CPM improved from **8.61** to **4.63** (**-46.19%**)\n\n### Audience Performance\n* Female impressions grew to **1.12M** (**+81.67%**)\n* Male impressions increased to **1.10M** (**+77.73%**)\n* Gender distribution remained balanced\n\n## 3. Segment Performance\n\n### Platform Results\n* Unity impressions grew to **2.22M** (**+79.69%**)\n* Unity female audience increased **+81.67%**\n* Unity male audience grew **+77.73%**\n\n### Geographic Insights\n* France led growth at **+97.09%**\n* Korea showed strong gains at **+86.19%**\n* UK improved significantly by **+83.81%**\n\n### Age Group Performance\n* 35-44 segment grew **+90.46%**\n* 45-54 increased **+88.10%**\n* 55+ improved **+83.58%**\n\n## 4. Focus Areas\n\n### Growth Opportunities\n* Scale France activity given **+97.09%** growth\n* Expand 35-44 targeting with **+90.46%** performance\n* Leverage female audience success at **+81.67%**\n\n### Optimization Areas\n* Monitor UK CPM efficiency at **-48.81%**\n* Maintain US cost improvements at **-45.93%**\n* Balance age group distribution across platforms',
  },
};

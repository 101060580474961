import React from 'react';
import styled from 'styled-components';
import { TreeNode } from './TreeNode';
import { NonKeyNodes } from './NonKeyNodes';

const GroupName = styled.div`
  margin-left: ${props => props.$level * 40 + 40}px;
  margin-top: 8px;
  color: #6b7280;
  font-size: 14px;
`;

export const Group = ({
  childGroups,
  splitHeaders,
  level,
  keyNodes,
  expandedNodes,
  expandAllNodes,
  onNodeClick,
  selectedNodeId,
}) => {
  const calculateGroupPriority = children => {
    if (Array.isArray(children)) {
      return children.reduce(
        (acc, child) => ({
          totalContribution: acc.totalContribution + Math.abs(child.contribution || 0),
          keyNodesCount: acc.keyNodesCount + (keyNodes[child.id] ? 1 : 0),
        }),
        { totalContribution: 0, keyNodesCount: 0 }
      );
    }
    return { totalContribution: 0, keyNodesCount: 0 };
  };

  const sortChildrenByPriority = children => {
    return [...children].sort((a, b) => {
      const aIsKey = !!keyNodes[a.id];
      const bIsKey = !!keyNodes[b.id];
      if (aIsKey !== bIsKey) return aIsKey ? -1 : 1;
      if (aIsKey && bIsKey) {
        return Math.abs(b.contribution || 0) - Math.abs(a.contribution || 0);
      }
      return 0;
    });
  };

  const childrenEntries = Object.entries(childGroups)
    .map(([splitKey, children]) => ({
      splitKey,
      children,
      priority: calculateGroupPriority(children),
    }))
    .sort((a, b) => {
      if (a.priority.keyNodesCount !== b.priority.keyNodesCount) {
        return b.priority.keyNodesCount - a.priority.keyNodesCount;
      }
      return Math.abs(b.priority.totalContribution) - Math.abs(a.priority.totalContribution);
    });

  return (
    <>
      {childrenEntries.map(({ splitKey, children }) => {
        if (Array.isArray(children)) {
          const sortedChildren = sortChildrenByPriority(children);
          const keyNodeChildren = sortedChildren.filter(child => keyNodes[child.id]);
          const nonKeyNodeChildren = sortedChildren.filter(child => !keyNodes[child.id]);

          const groupName = `By ${splitHeaders[splitKey]}` || splitKey;

          return (
            <div key={splitKey}>
              <GroupName $level={level}>{groupName}</GroupName>
              {keyNodeChildren.map(child => (
                <TreeNode
                  key={child.id}
                  node={child}
                  level={level + 1}
                  keyNodes={keyNodes}
                  expandedNodes={expandedNodes}
                  expandAllNodes={expandAllNodes}
                  onNodeClick={onNodeClick}
                  selectedNodeId={selectedNodeId}
                />
              ))}
              {nonKeyNodeChildren.length > 0 && (
                <NonKeyNodes
                  nodes={nonKeyNodeChildren}
                  level={level + 1}
                  keyNodes={keyNodes}
                  expandedNodes={expandedNodes}
                  expandAllNodes={expandAllNodes}
                  onNodeClick={onNodeClick}
                  selectedNodeId={selectedNodeId}
                />
              )}
            </div>
          );
        }

        return (
          <div key={splitKey}>
            <Group
              childGroups={children}
              splitKeyHeaders={splitHeaders}
              level={level + 1}
              keyNodes={keyNodes}
              expandedNodes={expandedNodes}
              expandAllNodes={expandAllNodes}
              onNodeClick={onNodeClick}
              selectedNodeId={selectedNodeId}
            />
          </div>
        );
      })}
    </>
  );
};
import './App.css';
import { useState, useEffect, useCallback, useMemo } from 'react';
import Charts from './chart/Charts';
import ConfigController from './controller/ConfigController';
import { Analysis, analysisService } from './chart/Analysis';
import { DIMENSIONS } from './utils/dimensionConfig';
import { BASE_URL } from './utils/constants';

const PasswordGate = ({ onCorrectPassword }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/verify_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        onCorrectPassword();
      } else {
        setError('Incorrect password');
        setPassword('');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      setPassword('');
    }
  };

  return (
    <div style={passwordGateStyles.passwordGateContainer}>
      <div style={passwordGateStyles.passwordGateBox}>
        <div>
          <h2 style={passwordGateStyles.passwordGateTitle}>Enter Password</h2>
        </div>
        <form style={passwordGateStyles.passwordForm} onSubmit={handleSubmit}>
          <div>
            <label htmlFor="password" style={passwordGateStyles.srOnly}>
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              style={passwordGateStyles.inputField}
              placeholder="Enter password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          {error && (
            <div style={passwordGateStyles.errorMessage}>
              <svg
                style={passwordGateStyles.errorIcon}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 14h2v2h-2v-2zm0-2V7h2v7h-2z"
                  clipRule="evenodd"
                />
              </svg>
              {error}
            </div>
          )}

          <div>
            <button type="submit" style={passwordGateStyles.submitButton}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const passwordGateStyles = {
  passwordGateContainer: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f9fafb',
  },
  passwordGateBox: {
    maxWidth: '28rem',
    width: '100%',
    padding: '2rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  passwordGateTitle: {
    textAlign: 'center',
    fontSize: '1.875rem',
    fontWeight: '800',
    color: '#111827',
  },
  passwordForm: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  srOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    borderWidth: '0',
  },
  inputField: {
    width: '100%',
    padding: '0.75rem 1rem',
    borderRadius: '0.375rem',
    border: '1px solid #d1d5db',
    fontSize: '0.875rem',
    color: '#111827',
    backgroundColor: 'white',
    outline: 'none',
    transition: 'all 0.15s ease',
    '&:focus': {
      borderColor: '#3b82f6',
      boxShadow: '0 0 0 3px rgba(59, 130, 246, 0.5)',
    },
  },
  submitButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0.5rem 1rem',
    border: 'none',
    borderRadius: '0.375rem',
    fontSize: '0.875rem',
    fontWeight: '500',
    color: 'white',
    backgroundColor: '#2563eb',
    cursor: 'pointer',
    transition: 'all 0.15s ease',
    '&:hover': {
      backgroundColor: '#1d4ed8',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 3px rgba(59, 130, 246, 0.5)',
    },
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '12px',
    backgroundColor: '#fee2e2',
    border: '1px solid #fca5a5',
    borderRadius: '6px',
    color: '#dc2626',
    fontSize: '14px',
  },
  errorIcon: {
    width: '16px',
    height: '16px',
    flexShrink: 0,
    color: '#dc2626',
  },
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    process.env.NODE_ENV === 'development' || false
  );

  return (
    <div>
      {!isAuthenticated ? (
        <PasswordGate onCorrectPassword={() => setIsAuthenticated(true)} />
      ) : (
        <ProtectedContent />
      )}
    </div>
  );
}

const useDataFetching = (selectedDimension, dataGenerated) => {
  const [chartData, setChartData] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasRenderedBefore, setHasRenderedBefore] = useState(false);

  const fetchData = useCallback(async () => {
    if (!dataGenerated) return;
    setIsLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/fetch_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Origin: 'http://localhost:3000',
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({ dimension: selectedDimension.col_name }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      setChartData(JSON.parse(data));
      setLastUpdate(new Date().toLocaleTimeString());
      if (data.length > 0) {
        setHasRenderedBefore(true);
      }
    } catch (err) {
      console.error('Error fetching chart data:', err);
      setChartData([]);
    } finally {
      setIsLoading(false);
    }
  }, [selectedDimension, dataGenerated]);

  return { chartData, lastUpdate, isLoading, hasRenderedBefore, fetchData };
};

const ProtectedContent = () => {
  const [dataGenerated, setDataGenerated] = useState(false);
  const [plotView, setPlotView] = useState(true);
  const [anomaliesToAnalyze, setAnomaliesToAnalyze] = useState({});
  const [clickedAnomalyKey, setClickedAnomalyKey] = useState(null);

  const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS.platform);
  const [selectedMetrics, setSelectedMetrics] = useState(['CPM', 'ROAS', 'CPI']);
  const [shouldClearAnomalies, setShouldClearAnomalies] = useState(false);

  const { chartData, lastUpdate, isLoading, hasRenderedBefore, fetchData } = useDataFetching(
    selectedDimension,
    dataGenerated
  );

  useEffect(() => {
    if (dataGenerated) {
      fetchData();
      analysisService.clearState();
      setAnomaliesToAnalyze({});
      setClickedAnomalyKey(null);
      setPlotView(true);
      setShouldClearAnomalies(true);
    }
  }, [dataGenerated]);

  useEffect(() => {
    if (dataGenerated && selectedDimension) {
      fetchData();
    }
  }, [selectedDimension, fetchData]);

  const handleAnomalySelect = useCallback((key, anomaly) => {
    setAnomaliesToAnalyze(prev => ({ ...prev, [key]: anomaly }));
    setClickedAnomalyKey(key);
    setPlotView(false);
  }, []);

  const chartProps = useMemo(
    () => ({
      dataGenerated,
      chartData,
      selectedDimension,
      selectedMetrics,
      hasRenderedBefore,
      shouldClearAnomalies,
      setSelectedDimension,
      setSelectedMetrics,
      setShouldClearAnomalies,
      onAnomalySelect: handleAnomalySelect,
    }),
    [
      dataGenerated,
      chartData,
      isLoading,
      lastUpdate,
      selectedDimension,
      selectedMetrics,
      hasRenderedBefore,
      shouldClearAnomalies,
      fetchData,
      handleAnomalySelect,
    ]
  );

  return (
    <div style={styles.app}>
      <header style={styles.header}>
        <h1 style={styles.title}>Performance Simulator</h1>
      </header>

      <main style={styles.mainContainer}>
        <aside style={styles.sidebar}>
          <ConfigController setDataGenerated={setDataGenerated} />
        </aside>
        <section style={styles.contentArea}>
          <nav style={styles.viewToggle}>
            <div style={styles.toggleContainer}>
              <button
                onClick={() => setPlotView(true)}
                style={{
                  ...styles.toggleBtn,
                  ...(plotView ? styles.toggleBtnActive : {}),
                  borderRadius: '4px 0 0 4px',
                }}
              >
                Plot View
              </button>
              <button
                onClick={() => setPlotView(false)}
                style={{
                  ...styles.toggleBtn,
                  ...(!plotView ? styles.toggleBtnActive : {}),
                  borderRadius: '0 4px 4px 0',
                }}
              >
                Analysis View
              </button>
            </div>
          </nav>

          <div style={{ display: plotView ? 'block' : 'none' }}>
            <Charts {...chartProps} />
          </div>
          <div style={{ display: plotView ? 'none' : 'block' }}>
            <Analysis
              anomaliesToAnalyze={anomaliesToAnalyze}
              clickedAnomalyKey={clickedAnomalyKey}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

const styles = {
  app: {
    height: '100vh',
    backgroundColor: '#f8f9fa',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: '18px',
    textAlign: 'center',
  },
  title: {
    fontSize: '2.0rem',
    color: 'black',
    margin: 0,
    fontWeight: '600',
  },
  mainContainer: {
    display: 'flex',
    gap: '18px',
    flex: 1,
    padding: '0 48px',
    paddingBottom: '16px',
    maxWidth: '1200px',
    margin: '0 auto',
    width: '100%',
    overflow: 'auto',
  },
  sidebar: {
    width: '25%',
    minWidth: '240px',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
    overflow: 'auto',
  },
  contentArea: {
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    minWidth: '600px',
    padding: '12px 18px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
    overflow: 'scroll',
  },
  viewToggle: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  toggleContainer: {
    display: 'inline-flex',
    padding: '4px',
    backgroundColor: '#e8eaed',
    borderRadius: '8px',
  },
  toggleBtn: {
    padding: '6px 12px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    color: '#5f6368',
    transition: 'all 0.2s ease',
    minWidth: '80px',
  },
  toggleBtnActive: {
    backgroundColor: 'white',
    color: '#1a73e8',
    boxShadow: '0 2px 6px rgba(0,0,0,0.12)',
  },
  sheetContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.12)',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
};

export default App;

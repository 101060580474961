import React, { useState, useRef, useEffect } from 'react';
import { DIMENSIONS } from '../utils/dimensionConfig';
import { BASE_URL } from '../utils/constants';

const ANOMALY_COLOR = 'red';

const SENSITIVITY_OPTIONS = [
  { value: 'low', label: 'Low Sensitivity' },
  { value: 'high', label: 'High Sensitivity' },
];

const SensitivityDropdown = ({ onSelect, show }) => {
  if (!show) return null;

  return (
    <div style={styles.dropdown}>
      {SENSITIVITY_OPTIONS.map(option => (
        <button
          key={option.value}
          onClick={() => onSelect(option.value)}
          style={styles.dropdownItem}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export const Anomalies = ({ metric, render, clearAnomalies, onAnomaliesCleared }) => {
  const platforms = DIMENSIONS.platform.segments.map(segment => segment.id);
  const [anomalies, setAnomalies] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAnomalies, setShowAnomalies] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (clearAnomalies) {
      setAnomalies(null);
      setShowAnomalies(false);
      onAnomaliesCleared();
    }
  }, [clearAnomalies, onAnomaliesCleared]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const metricKey = metric.toLowerCase();

  const fetchAnomalies = async sensitivity => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/detect_anomalies`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Origin: 'http://localhost:3000',
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({
          metrics: [metricKey],
          sensitivity,
        }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const { anomalies } = await response.json();
      setAnomalies(anomalies);
      setShowAnomalies(true);
    } catch (error) {
      console.error('Error detecting anomalies:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSensitivitySelect = sensitivity => {
    setShowDropdown(false);
    fetchAnomalies(sensitivity);
  };

  const getAnomalyDatasets = () => {
    if (!showAnomalies || !anomalies) return [];

    const allPoints = platforms.reduce((acc, platform) => {
      const platformData = anomalies?.[metricKey]?.[platform];
      if (!platformData) return acc;

      const points = platformData.dates.map((date, index) => {
        return {
          x: date,
          y: platformData.values[index],
          backgroundColor: ANOMALY_COLOR,
        };
      });

      return [...acc, ...points];
    }, []);
    return [
      {
        label: 'Anomalies',
        data: allPoints,
        type: 'scatter',
        pointStyle: 'circle',
        radius: 5,
        hoverRadius: 7,
        borderColor: ANOMALY_COLOR,
        backgroundColor: ANOMALY_COLOR,
        borderWidth: 1,
        order: 1,
      },
    ];
  };

  return render(
    showAnomalies ? getAnomalyDatasets() : [],
    <div style={styles.controls}>
      <div style={styles.dropdownContainer} ref={buttonRef}>
        <button
          onClick={() => (showAnomalies ? setShowAnomalies(false) : setShowDropdown(!showDropdown))}
          disabled={isLoading}
          style={styles.anomalyButton}
        >
          {isLoading ? 'Detecting...' : showAnomalies ? 'Hide Anomalies' : 'Find Anomalies'}
        </button>
        <SensitivityDropdown show={showDropdown} onSelect={handleSensitivitySelect} />
      </div>
    </div>
  );
};

const styles = {
  controls: {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    gap: '8px',
  },
  anomalyButton: {
    height: '24px',
    width: '100px',
    padding: '0 8px',
    fontSize: '12px',
    fontWeight: '500',
    color: 'white',
    backgroundColor: '#1a73e8',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropdownContainer: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: '100%',
    left: 0,
    marginTop: '4px',
    backgroundColor: 'white',
    border: '1px solid #e2e8f0',
    borderRadius: '6px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 10,
    width: '200px',
  },
  dropdownItem: {
    width: '100%',
    padding: '8px 12px',
    border: 'none',
    background: 'none',
    textAlign: 'left',
    fontSize: '12px',
    color: '#1e293b',
    cursor: 'pointer',
    display: 'block',
    '&:hover': {
      backgroundColor: '#f8fafc',
    },
  },
};

import React, { useState,  useMemo } from 'react';
import styled from 'styled-components';
import { Spinner } from '../atoms/Spinner';
import { TreeNode } from './treeNode/TreeNode';
import { countAllNodes } from './useTree';


const getExpandedNodes = (node, keyNodes, expandedNodes = new Set()) => {
  if (!node.children) {
    return keyNodes[node.id];
  }

  let shouldExpand = keyNodes[node.id];

  Object.values(node.children).forEach(children => {
    if (Array.isArray(children)) {
      children.forEach(child => {
        if (getExpandedNodes(child, keyNodes, expandedNodes)) {
          shouldExpand = true;
          expandedNodes.add(node.id);
        }
      });
    } else {
      Object.values(children).forEach(subChildren => {
        subChildren.forEach(child => {
          if (getExpandedNodes(child, keyNodes, expandedNodes)) {
            shouldExpand = true;
            expandedNodes.add(node.id);
          }
        });
      });
    }
  });

  if (shouldExpand) {
    expandedNodes.add(node.id);
  }

  return shouldExpand;
};


const TreeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
`;

const TreeStats = styled.div`
  padding: 8px 16px;
  background: #f3f4f6;
  border-radius: 6px;
  color: #4b5563;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
`;

const ExpandToggle = styled.button`
  padding: 6px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: white;
  color: #4b5563;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    background: #f9fafb;
    border-color: #9ca3af;
    color: #374151;
    transform: translateX(2px);
  }

  &:active {
    background: #f3f4f6;
    transform: translateX(0);
  }
`;

const LoadingKeyNodes = styled(ExpandToggle)`
  cursor: default;

  &:hover {
    background: #f9fafb;
    color: #9ca3af;
    border-color: #e5e7eb;
    transform: none;
  }
  background: #f9fafb;
  color: #9ca3af;
`;

const Tree = ({ tree, keyNodes, onNodeSelect, loading }) => {
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [expandAllNodes, setExpandAllNodes] = useState(Object.keys(keyNodes).length === 0);

  const expandedNodes = useMemo(() => {
    const nodes = new Set();
    getExpandedNodes(tree, keyNodes, nodes);
    return nodes;
  }, [tree, keyNodes]);

  const totalNodes = useMemo(() => countAllNodes(tree), [tree]);

  const handleNodeClick = node => {
    setSelectedNodeId(node.id);
    onNodeSelect?.(node);
  };

  return (
    <TreeWrapper>
      <TreeStats>
        <span>Total nodes: {totalNodes}</span>
        <ActionWrapper>
          {loading ? (
            <LoadingKeyNodes as="div">
              Loading
              <Spinner size="14px" color="#6b7280" />
            </LoadingKeyNodes>
          ) : (
            <ExpandToggle onClick={() => setExpandAllNodes(prev => !prev)}>
              {expandAllNodes ? 'Show key nodes' : 'Expand all'}
            </ExpandToggle>
          )}
        </ActionWrapper>
      </TreeStats>
      <TreeNode
        node={tree}
        keyNodes={keyNodes}
        expandedNodes={expandedNodes}
        expandAllNodes={expandAllNodes}
        onNodeClick={handleNodeClick}
        selectedNodeId={selectedNodeId}
        isRoot
      />
    </TreeWrapper>
  );
};

export default Tree;

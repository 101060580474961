const DIMENSIONS = {
  platform: {
    name: 'Platform',
    col_name: 'platform',
    segments: [
      { id: 'Meta', name: 'Meta' },
      { id: 'TikTok', name: 'TikTok' },
      { id: 'Unity', name: 'Unity' },
      { id: 'AppLovin', name: 'AppLovin' },
    ],
  },
  age_groups: {
    name: 'Age Groups',
    col_name: 'age_group',
    segments: [
      {
        id: '18-24',
        name: 'Under 25',
        weightsByPlatform: { Meta: 25, TikTok: 45, AppLovin: 30, Unity: 35 },
      },
      {
        id: '25-34',
        name: '25-34',
        weightsByPlatform: { Meta: 35, TikTok: 30, AppLovin: 40, Unity: 35 },
      },
      {
        id: '35-44',
        name: '35-44',
        weightsByPlatform: { Meta: 20, TikTok: 15, AppLovin: 20, Unity: 20 },
      },
      {
        id: '45-54',
        name: '45-54',
        weightsByPlatform: { Meta: 15, TikTok: 7, AppLovin: 7, Unity: 7 },
      },
      {
        id: '55+',
        name: 'Over 55',
        weightsByPlatform: { Meta: 5, TikTok: 3, AppLovin: 3, Unity: 3 },
      },
    ],
  },
  countries: {
    name: 'Country',
    col_name: 'country',
    segments: [
      {
        id: 'US',
        name: 'United States',
        weightsByPlatform: { Meta: 30, TikTok: 20, AppLovin: 35, Unity: 30 },
      },
      {
        id: 'UK',
        name: 'United Kingdom',
        weightsByPlatform: { Meta: 15, TikTok: 15, AppLovin: 12, Unity: 15 },
      },
      {
        id: 'JP',
        name: 'Japan',
        weightsByPlatform: { Meta: 10, TikTok: 15, AppLovin: 15, Unity: 20 },
      },
      {
        id: 'KR',
        name: 'South Korea',
        weightsByPlatform: { Meta: 10, TikTok: 20, AppLovin: 13, Unity: 15 },
      },
      {
        id: 'DE',
        name: 'Germany',
        weightsByPlatform: { Meta: 12, TikTok: 8, AppLovin: 10, Unity: 8 },
      },
      {
        id: 'FR',
        name: 'France',
        weightsByPlatform: { Meta: 8, TikTok: 7, AppLovin: 5, Unity: 5 },
      },
      {
        id: 'BR',
        name: 'Brazil',
        weightsByPlatform: { Meta: 7, TikTok: 8, AppLovin: 5, Unity: 4 },
      },
      {
        id: 'IN',
        name: 'India',
        weightsByPlatform: { Meta: 8, TikTok: 7, AppLovin: 5, Unity: 3 },
      },
    ],
  },
  gender: {
    name: 'Gender',
    col_name: 'gender',
    segments: [
      {
        id: 'M',
        name: 'Male',
        weightsByPlatform: { Meta: 48, TikTok: 35, AppLovin: 55, Unity: 60 },
      },
      {
        id: 'F',
        name: 'Female',
        weightsByPlatform: { Meta: 52, TikTok: 65, AppLovin: 45, Unity: 40 },
      },
    ],
  },
};

export { DIMENSIONS };

import styled from 'styled-components';

const SpinnerElement = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  border: 2px solid ${props => props.color || '#1a73e8'};
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = ({ size = '12px', color = '#1a73e8', ...props }) => {
  return <SpinnerElement size={size} color={color} {...props} />;
};

export const Divider = ({ margin = '6px 0', color = '#e2e8f0' }) => {
  return (
    <hr
      style={{
        border: 'none',
        height: '1px',
        backgroundColor: color,
        margin,
      }}
    />
  );
};

import React, { useState } from 'react';
import { generateTimeSeed } from '../utils/seedGenerator';

const deepFreeze = obj => {
  Object.keys(obj).forEach(prop => {
    if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      deepFreeze(obj[prop]);
    }
  });
  return Object.freeze(obj);
};

const PRESETS = deepFreeze({
  preset_0: {
    desc: 'All platforms',
    start_date: '2024-01-01',
    days: 90,
    dynamic_allocation: true,
    platform_config: {
      Meta: {
        pattern: {
          type: 'trend',
          severity: 'high_positive',
        },
        volatility: 'low',
        seasonality: 'high',
      },
      TikTok: {
        pattern: {
          type: 'spike',
          severity: 'low',
        },
        volatility: 'high',
        seasonality: 'high',
      },
      AppLovin: {
        pattern: {
          type: 'trend',
          severity: 'low_positive',
        },
        volatility: 'high',
        seasonality: 'low',
      },
      Unity: {
        pattern: {
          type: 'cyclical',
          severity: 'high',
        },
        volatility: 'low',
        seasonality: 'low',
      },
    },
    dimensions: {
      age_groups: {
        Meta: {
          '18-24': 25,
          '25-34': 35,
          '35-44': 20,
          '45-54': 15,
          '55+': 5,
        },
        TikTok: {
          '18-24': 45,
          '25-34': 30,
          '35-44': 15,
          '45-54': 7,
          '55+': 3,
        },
        AppLovin: {
          '18-24': 30,
          '25-34': 40,
          '35-44': 20,
          '45-54': 7,
          '55+': 3,
        },
        Unity: {
          '18-24': 35,
          '25-34': 35,
          '35-44': 20,
          '45-54': 7,
          '55+': 3,
        },
      },
    },
    budget: {
      total_daily: 10000,
      distribution: {
        Meta: 42,
        TikTok: 18,
        AppLovin: 37,
        Unity: 3,
      },
    },
    anomalies: [
      { date: '2024-01-15', platform: 'Meta', impact: 'high' },
      { date: '2024-02-01', platform: 'TikTok', impact: 'high' },
    ],
    market_competition: 'high',
  },
  preset_1: {
    desc: 'Unity Cyclical',
    start_date: '2024-04-01',
    days: 90,
    dynamic_allocation: true,
    platform_config: {
      Unity: {
        pattern: {
          type: 'cyclical',
          severity: 'high',
        },
        volatility: 'high',
        seasonality: 'low',
      },
    },
    dimensions: {
      age_groups: {
        Unity: {
          '18-24': 35,
          '25-34': 35,
          '35-44': 20,
          '45-54': 7,
          '55+': 3,
        },
      },
      gender: {
        Unity: {
          F: 40,
          M: 60,
        },
      },
      countries: {
        Unity: {
          US: 30,
          UK: 15,
          JP: 20,
          KR: 15,
          DE: 8,
          FR: 5,
          BR: 4,
          IN: 3,
        },
      },
    },
    budget: {
      total_daily: 10000,
      distribution: {
        Unity: 100,
      },
    },
    anomalies: [{ date: '2024-05-15', platform: 'Unity', impact: 'high' }],
    market_competition: 'high',
  },
  preset_2: {
    desc: 'AppLovin Trending Down',
    start_date: '2024-09-01',
    days: 90,
    dynamic_allocation: true,
    platform_config: {
      AppLovin: {
        pattern: {
          type: 'trend',
          severity: 'high_negative',
        },
        volatility: 'low',
        seasonality: 'high',
      },
    },
    dimensions: {
      age_groups: {
        AppLovin: {
          '18-24': 30,
          '25-34': 40,
          '35-44': 20,
          '45-54': 7,
          '55+': 3,
        },
      },
      gender: {
        AppLovin: {
          F: 45,
          M: 55,
        },
      },
      countries: {
        AppLovin: {
          US: 35,
          UK: 12,
          JP: 15,
          KR: 13,
          DE: 10,
          FR: 5,
          BR: 5,
          IN: 5,
        },
      },
    },
    budget: {
      total_daily: 10000,
      distribution: {
        AppLovin: 100,
      },
    },
    anomalies: [{ date: '2024-10-15', platform: 'AppLovin', impact: 'high' }],
    market_competition: 'high',
  },
  preset_3: {
    desc: 'Holiday Season',
    start_date: '2024-11-01',
    days: 60,
    platform_config: {
      Meta: {
        pattern: {
          type: 'seasonal',
          severity: 'high',
        },
        volatility: 'high',
        seasonality: 'very_high',
      },
      TikTok: {
        pattern: {
          type: 'seasonal',
          severity: 'high',
        },
        volatility: 'high',
        seasonality: 'very_high',
      },
      Unity: {
        pattern: {
          type: 'seasonal',
          severity: 'medium',
        },
        volatility: 'medium',
        seasonality: 'high',
      },
    },
    dimensions: {
      age_groups: {
        Meta: {
          '18-24': 30,
          '25-34': 35,
          '35-44': 20,
          '45-54': 10,
          '55+': 5,
        },
        TikTok: {
          '18-24': 45,
          '25-34': 30,
          '35-44': 15,
          '45-54': 7,
          '55+': 3,
        },
        Unity: {
          '18-24': 35,
          '25-34': 35,
          '35-44': 20,
          '45-54': 7,
          '55+': 3,
        },
      },
    },
    budget: {
      total_daily: 15000, // Higher holiday budget
      distribution: {
        Meta: 45,
        TikTok: 35,
        Unity: 20,
      },
    },
    anomalies: [
      { date: '2024-11-24', platform: 'Meta', impact: 'high' }, // Black Friday
      { date: '2024-12-24', platform: 'All', impact: 'high' }, // Christmas
    ],
    market_competition: 'high',
    dynamic_allocation: true,
  },
  preset_4: {
    desc: 'Social Media Focus (Meta + TikTok)',
    start_date: '2024-06-01',
    days: 90,
    platform_config: {
      Meta: {
        pattern: {
          type: 'trend',
          severity: 'low_positive',
        },
        volatility: 'medium',
        seasonality: 'high',
      },
      TikTok: {
        pattern: {
          type: 'trend',
          severity: 'high_positive',
        },
        volatility: 'high',
        seasonality: 'high',
      },
    },
    dimensions: {
      age_groups: {
        Meta: {
          '18-24': 35,
          '25-34': 40,
          '35-44': 15,
          '45-54': 7,
          '55+': 3,
        },
        TikTok: {
          '18-24': 50,
          '25-34': 30,
          '35-44': 12,
          '45-54': 5,
          '55+': 3,
        },
      },
    },
    budget: {
      total_daily: 10000,
      distribution: {
        Meta: 60,
        TikTok: 40,
      },
    },
    anomalies: [{ date: '2024-07-01', platform: 'TikTok', impact: 'high' }],
    market_competition: 'high',
    dynamic_allocation: true,
  },
});

const PresetSelection = ({
  startSimulation,
  onSimulationComplete,
  setConfig,
  blankConfig,
  setShowPresetSelection,
}) => {
  const [currentConfig, setCurrentConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onManualSetup = () => {
    setConfig(blankConfig);
    setShowPresetSelection(false);
  };

  const handlePresetChange = e => {
    const presetKey = e.target.value;
    if (!presetKey) {
      setCurrentConfig(null);
      return;
    }

    setCurrentConfig({
      ...PRESETS[e.target.value],
      seed: 0,
      desc: e.target.value,
    });
  };

  const onEdit = () => {
    setConfig(currentConfig);
    setShowPresetSelection(false);
  };
  const onStartSimulation = async () => {
    setIsLoading(true);
    try {
      setConfig(currentConfig);
      await startSimulation(currentConfig);
      onSimulationComplete();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container" style={{ width: '100%' }}>
      <button
        onClick={onManualSetup}
        className={`preset-button button-primary ${currentConfig ? 'disabled' : ''}`}
        disabled={!!currentConfig}
      >
        Setup Manually
      </button>

      <div className="divider">
        <span>OR</span>
      </div>

      <h2
        style={{
          color: 'var(--text-primary)',
          margin: '0',
        }}
      >
        Choose From Presets
      </h2>
      <select
        value={(currentConfig && currentConfig.desc) || ''}
        onChange={handlePresetChange}
        className="preset-dropdown"
      >
        <option value="">Select a preset...</option>
        {Object.entries(PRESETS).map(([key, config]) => (
          <option key={key} value={key}>
            {config.desc}
          </option>
        ))}
      </select>

      {currentConfig && (
        <>
          <div className="preset-overview">
            <div className="preset-details">
              <div className="info-section">
                <h4 className="preset-title">Campaign Overview</h4>
                <div className="info-list">
                  <div className="info-item">Seed: {currentConfig.seed}</div>
                  <div className="info-item">Duration: {currentConfig.days} days</div>
                  <div className="info-item">Start: {currentConfig.start_date}</div>
                  <div className="info-item">
                    Daily Budget:{' '}
                    <span className="highlight">
                      ${currentConfig.budget.total_daily.toLocaleString()}
                    </span>
                  </div>
                  <div className="info-item">
                    Market Competition: {currentConfig.market_competition}
                  </div>
                </div>
              </div>

              <div className="info-section">
                <h4 className="preset-title">Platform Configuration</h4>
                <ul className="info-list">
                  {Object.entries(currentConfig.platform_config).map(([platform, config]) => (
                    <li key={platform} className="info-item">
                      {platform}: {config.pattern.type} {config.pattern.severity} (Seasonality:{' '}
                      {config.seasonality}, Volatility: {config.volatility})
                    </li>
                  ))}
                </ul>
              </div>

              <div className="info-section">
                <h4 className="preset-title">Budget Distribution</h4>
                <ul className="info-list">
                  {Object.entries(currentConfig.budget.distribution).map(([platform, share]) => (
                    <li key={platform} className="info-item">
                      {platform}: {share}% ($
                      {((currentConfig.budget.total_daily * share) / 100).toLocaleString()}/day)
                    </li>
                  ))}
                </ul>
              </div>

              <div className="info-section">
                <h4 className="preset-title">Scheduled Anomalies</h4>
                <ul className="info-list">
                  {currentConfig.anomalies.map((anomaly, index) => (
                    <li key={index} className="info-item">
                      {new Date(anomaly.date).toLocaleDateString()}: {anomaly.platform} (Impact:{' '}
                      {anomaly.impact})
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <button
            onClick={onStartSimulation}
            className="button-primary preset-button"
            disabled={isLoading}
          >
            {isLoading ? 'Starting Simulation...' : 'Start'}
          </button>
          <button onClick={onEdit} className="button-secondary preset-button">
            Edit
          </button>
        </>
      )}
    </div>
  );
};

export default PresetSelection;

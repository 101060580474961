import React, { useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Anomalies } from './Anomalies';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ScatterController,
} from 'chart.js';
import { DIMENSIONS } from '../utils/dimensionConfig';
import CrosshairPlugin from 'chartjs-plugin-crosshair';

const ANOMALY_ONLY_ANALYSIS = false;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CrosshairPlugin,
  ScatterController
);

const METRICS = [
  'ROAS',
  'CPM',
  'CVR',
  'CTR',
  'CPI',
  'LTV',
  'Spend',
  'Impressions',
  'Clicks',
  'Installs',
  'Revenue',
];

const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: { beginAtZero: true },
    x: {
      grid: {
        display: true,
        drawOnChartArea: false,
      },
      ticks: {
        maxTicksLimit: 14,
        autoSkip: true,
      },
    },
  },
  interaction: {
    mode: 'index',
    intersect: false,
    axis: 'x',
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: context => {
          if (context.dataset.type === 'scatter') {
            return `Anomaly: ${context.parsed.y?.toFixed(2) || 'N/A'}`;
          }
          return `${context.dataset.label}: ${context.parsed.y?.toFixed(2) || 'N/A'}`;
        },
      },
    },
    crosshair: {
      line: {
        color: '#666',
        width: 1,
        dashPattern: [5, 5],
      },
      sync: {
        enabled: true,
        group: 1,
      },
      zoom: {
        enabled: false,
      },
      snap: {
        enabled: true,
      },
    },
  },
};

const CHART_COLORS = [
  '#fd7f6f', // Light Red
  '#b2e061', // Light Green
  '#bd7ebe', // Light Purple
  '#ffb55a', // Light Orange
  '#ffee65', // Light Yellow
  '#beb9db', // Light Lavender
  '#fdcce5', // Light Pink
  '#8bd3c7', // Light Teal
];

const getChartDatasets = (chartData, metric, dimension, anomalyDatasets = []) => {
  const allDates = [...new Set(chartData.map(d => d.date))].sort();
  const dimensionValues = [...new Set(chartData.map(d => d[dimension.col_name]))];

  const dimensionDatasets = dimensionValues.filter(Boolean).map((value, index) => {
    const color = CHART_COLORS[index % CHART_COLORS.length];
    return {
      label: `${value}`,
      data: allDates.map(date => {
        const point = chartData.find(d => d.date === date && d[dimension.col_name] === value);
        return point?.[metric.toLowerCase()] || null;
      }),
      borderColor: color,
      backgroundColor: color,
      borderWidth: 2,
      fill: false,
      tension: 0.1,
      pointRadius: 1.5,
      pointHoverRadius: 4,
      order: 2,
    };
  });

  const mappedAnomalyDatasets = anomalyDatasets.map(anomalySet => ({
    ...anomalySet,
    data: allDates.map(date => {
      const anomalyPoint = anomalySet.data.find(d => d.x === date);
      return anomalyPoint ? anomalyPoint.y : null;
    }),
  }));

  return {
    labels: allDates,
    datasets: [...dimensionDatasets, ...mappedAnomalyDatasets],
  };
};

const DimensionSelector = ({ selectedDimension, onDimensionChange }) => (
  <div style={styles.dimensionSelector}>
    <h3>Group By</h3>
    <select
      value={Object.keys(DIMENSIONS).find(
        key => DIMENSIONS[key].col_name === selectedDimension.col_name
      )}
      onChange={e => onDimensionChange(DIMENSIONS[e.target.value])}
      style={styles.dimensionSelect}
    >
      {Object.keys(DIMENSIONS).map(key => (
        <option key={key} value={key}>
          {key}
        </option>
      ))}
    </select>
  </div>
);

const MetricSelector = ({ selectedMetrics, onMetricChange }) => (
  <div style={styles.metricSelector}>
    {METRICS.map(metric => (
      <label
        key={metric}
        style={{
          ...styles.metricButton,
          ...(selectedMetrics.includes(metric) && styles.metricButtonSelected),
        }}
      >
        <input
          type="checkbox"
          style={styles.metricButtonInput}
          checked={selectedMetrics.includes(metric)}
          onChange={() => onMetricChange(metric)}
        />
        {metric}
      </label>
    ))}
  </div>
);

const Chart = ({
  metric,
  chartData,
  dimension,
  clearAnomalies,
  onAnomaliesCleared,
  onAnomalyClicked,
}) => {
  const chartRef = React.useRef(null);
  return (
    <div style={styles.chartContainer}>
      {chartData.length > 0 ? (
        <Anomalies
          metric={metric}
          clearAnomalies={clearAnomalies}
          onAnomaliesCleared={onAnomaliesCleared}
          render={(anomalyDataset, controls) => {
            const chartDatasets = getChartDatasets(chartData, metric, dimension, anomalyDataset);
            const chartOptions = {
              ...CHART_OPTIONS,
              scales: {
                ...CHART_OPTIONS.scales,
                y: {
                  ...CHART_OPTIONS.scales.y,
                  ticks: {
                    callback: function (value) {
                      if (['CTR', 'CVR'].includes(metric)) {
                        return (value * 100).toFixed(2) + '%';
                      }
                      return value;
                    },
                  },
                },
              },
              plugins: {
                ...CHART_OPTIONS.plugins,
                tooltip: {
                  ...CHART_OPTIONS.plugins.tooltip,
                  callbacks: {
                    ...CHART_OPTIONS.plugins.tooltip.callbacks,
                    label: function (context) {
                      if (context.datasetIndex === undefined) return null;

                      if (context.dataset.type === 'scatter') {
                        return `Anomaly: ${context.parsed.y?.toFixed(2) || 'N/A'}`;
                      }
                      return `${context.dataset.label}: ${context.parsed.y?.toFixed(2) || 'N/A'}`;
                    },
                    footer: function (context) {
                      const dataIndex = context[0].dataIndex;
                      if (dataIndex < 7) {
                        return [
                          'Cannot analyze any data point less',
                          'than 7 days from the starting point',
                          'due to insufficient historical context',
                        ];
                      }
                      return null;
                    },
                  },
                },
              },
              onHover: (_, elements, chart) => {
                if (!chart?.canvas) return;

                if (!elements.length) {
                  chart.canvas.style.cursor = 'default';
                  return;
                }

                const element = elements[0];
                chart.canvas.style.cursor = element.index < 7 ? 'not-allowed' : 'pointer';
              },
              onClick: (_, elements) => {
                if (elements.length > 0) {
                  const dimensionDatasets = chartDatasets.datasets.filter(
                    d => d.type !== 'scatter'
                  );

                  const targetElement = ANOMALY_ONLY_ANALYSIS
                    ? elements.find(element => element.datasetIndex >= dimensionDatasets.length)
                    : elements[0];

                  if (!targetElement) return;

                  if (targetElement.index < 7) return;

                  const date = chartDatasets.labels[targetElement.index];
                  const key = JSON.stringify({
                    metric: metric.toLowerCase(),
                    date: date,
                  });
                  onAnomalyClicked(key, { date, metric });
                }
              },
            };
            return (
              <div style={styles.chartGrid}>
                <h2 style={styles.chartTitle}>{metric}</h2>
                {dimension === DIMENSIONS.platform && (
                  <div style={styles.anomalyControls}>{controls}</div>
                )}
                <div style={styles.chartArea}>
                  <Line data={chartDatasets} options={chartOptions} ref={chartRef} />
                </div>
              </div>
            );
          }}
        />
      ) : (
        <div>
          <h3 style={styles.emptyStateTitle}>{dimension.name} Group By Not Configured</h3>
          <p style={styles}>Configure the {dimension.name} group by to view metrics</p>
        </div>
      )}
    </div>
  );
};

const Charts = ({
  dataGenerated,
  chartData,
  selectedDimension,
  selectedMetrics,
  hasRenderedBefore,
  shouldClearAnomalies,
  setSelectedDimension,
  setSelectedMetrics,
  setShouldClearAnomalies,
  onAnomalySelect,
}) => {
  const handleMetricChange = useCallback(
    metric => {
      setSelectedMetrics(prev =>
        prev.includes(metric) ? prev.filter(m => m !== metric) : [...prev, metric]
      );
    },
    [setSelectedMetrics]
  );

  if (!hasRenderedBefore && !dataGenerated) {
    return (
      <div style={styles.chartContainer}>
        <div>
          <h3 style={styles.emptyStateTitle}>No Data Available</h3>
          <p style={styles}>Configure and run simulation to view metrics</p>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.selectorsRow}>
        <DimensionSelector
          selectedDimension={selectedDimension}
          onDimensionChange={setSelectedDimension}
        />
        <MetricSelector selectedMetrics={selectedMetrics} onMetricChange={handleMetricChange} />
      </div>
      {selectedMetrics.map(metric => (
        <Chart
          key={metric}
          metric={metric}
          chartData={chartData}
          dimension={selectedDimension}
          clearAnomalies={shouldClearAnomalies}
          onAnomaliesCleared={() => setShouldClearAnomalies(false)}
          onAnomalyClicked={(key, anomaly) => onAnomalySelect(key, anomaly)}
        />
      ))}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    minWidth: '500px',
  },
  selectorsRow: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    padding: '12px',
    background: '#f8f9fa',
    borderRadius: '12px',
    justifyContent: 'space-between',
  },
  metricSelector: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'nowrap',
    justifyContent: 'start',
    overflowX: 'auto',
    flex: 1,
    background: '#f8f9fa',
    borderRadius: '12px',
    padding: '12px',
    boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.2)',
  },
  metricButton: {
    position: 'relative',
    padding: '6px 10px',
    background: 'white',
    color: '#4a5568',
    borderRadius: '18px',
    cursor: 'pointer',
    userSelect: 'none',
    boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
    transition: 'all 0.2s ease',
    border: '1px solid #e2e8f0',
    fontSize: '12px',
    fontWeight: '500',
  },
  metricButtonSelected: {
    background: '#1a73e8',
    color: 'white',
    border: '1px solid #1a73e8',
  },
  metricButtonInput: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
  },
  dimensionSelector: {
    minWidth: '120px',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  dimensionSelect: {
    width: '100%',
    padding: '8px 12px',
    fontSize: '10px',
    fontWeight: '500',
    color: '#4a5568',
    backgroundColor: 'white',
    border: '1px solid #e2e8f0',
    borderRadius: '8px',
    cursor: 'pointer',
    outline: 'none',
    transition: 'all 0.2s ease',
  },
  chartContainer: {
    background: 'white',
    borderRadius: '12px',
    padding: '12px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.24)',
  },
  chartGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: 'auto 1fr',
  },
  chartTitle: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#1e293b',
    margin: 0,
    gridColumn: '1',
    gridRow: '1',
    alignSelf: 'center',
  },
  anomalyControls: {
    gridColumn: '2',
    gridRow: '1',
  },
  chartArea: {
    gridColumn: '1 / -1',
    gridRow: '2',
    height: '360px',
  },
  emptyStateTitle: {
    marginBottom: '12px',
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
};

export default Charts;

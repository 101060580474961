import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatValue } from '../useTree';
import { Divider } from '../../atoms/Divider';

const Container = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  padding: 12px;
  margin: 8px 0;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateX(4px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  ${props =>
    props.$isHighlighted &&
    `
    border-color: #fbbf24;
    background-color: #fef3c7;
    box-shadow: 0 2px 8px rgba(251, 191, 36, 0.2);
  `}

  ${props =>
    props.$isSelected &&
    `
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  `}
`;

const ExpandIcon = styled.span`
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
  font-size: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #f3f4f6;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  gap: 4px;
  grid-template-areas:
    'header contribution'
    'metrics contribution'
    'description description';
  grid-template-columns: 5fr 2fr;

  ${props =>
    !props.$hasContribution &&
    `
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "metrics"
      "description";
  `}
`;

const Header = styled.div`
  grid-area: header;
  font-weight: 600;
  color: #1f2937;
  font-size: 14px;
`;

const MetricWrapper = styled.div`
  grid-area: metrics;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MetricValue = styled.div`
  font-weight: 600;
  color: #111827;
  font-size: 20px;
`;

const ContributionValue = styled.div`
  font-weight: 600;
  color: #111827;
  font-size: 16px;
`;

const ComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #6b7280;
  font-size: 13px;
`;

const ComparisonRow = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

const ChangeIndicator = styled.span`
  ${props => (props.$isPositive ? 'color: #059669;' : 'color: #dc2626;')}
  font-weight: 500;
  display: flex;
  align-items: baseline;
  gap: 2px;
`;

const MetricLabel = styled.div`
  color: #6b7280;
  font-size: 12px;
`;

const formatShortNumber = num => {
  const abs = Math.abs(num);
  if (abs >= 1000000) {
    return `${(num / 1000000).toFixed(2)}M`;
  }
  if (abs >= 1000) {
    return `${(num / 1000).toFixed(2)}K`;
  }
  return num.toFixed(2);
};

const ContributionWrapper = styled.div`
  grid-area: contribution;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: auto 0;
`;

const ContributionBar = styled.div`
  height: 4px;
  background: ${props => (props.$value > 0 ? '#dcfce7' : '#fee2e2')};
  border-radius: 2px;
  overflow: hidden;
`;

const ContributionFill = styled.div.attrs(props => ({
  style: {
    width: `${Math.abs(props.$value * 100)}%`,
    background: props.$value > 0 ? '#059669' : '#dc2626',
  },
}))`
  height: 100%;
`;

const ContributionDetail = styled.div`
  font-size: 12px;
  color: #4b5563;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const ContributionType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  color: #6b7280;
`;

const ContributionTypeBar = styled(ContributionBar)`
  height: 3px;
  background: ${props => (props.$type === 'metric' ? '#dbeafe' : '#f3e8ff')};
`;

const ContributionTypeFill = styled.div.attrs(props => ({
  style: {
    width: `${Math.abs(props.$value * 100)}%`,
    background: props.$type === 'metric' ? '#3b82f6' : '#8b5cf6',
  },
}))`
  height: 100%;
`;

const ContributionContent = ({ node }) => {
  const formatPercent = value => `${(value * 100).toFixed(1)}%`;
  const metadata = node.contribution_metadata;

  const hasValidMetadata = metadata => {
    return (
      metadata &&
      'metric_contribution_to_root' in metadata &&
      'weight_contribution_to_root' in metadata
    );
  };
  if (!hasValidMetadata(metadata)) {
    return (
      <>
        <MetricLabel>Contribution</MetricLabel>
        <ContributionValue>{formatPercent(node.contribution_to_root)}</ContributionValue>
        <ContributionBar $value={node.contribution_to_root}>
          <ContributionFill $value={node.contribution_to_root} />
        </ContributionBar>
      </>
    );
  }

  return (
    <>
      <MetricLabel>Contribution: {formatPercent(node.contribution_to_root)}</MetricLabel>
      <ContributionDetail>
        <ContributionType>
          <span>Metric Effect</span>
          <span>{formatPercent(metadata.metric_contribution_to_root)}</span>
        </ContributionType>
        <ContributionTypeBar $type="metric" $value={metadata.metric_contribution_to_root}>
          <ContributionTypeFill $type="metric" $value={metadata.metric_contribution_to_root} />
        </ContributionTypeBar>

        <ContributionType>
          <span>Weight Shift</span>
          <span>{formatPercent(metadata.weight_contribution_to_root)}</span>
        </ContributionType>
        <ContributionTypeBar $type="weight" $value={metadata.weight_contribution_to_root}>
          <ContributionTypeFill $type="weight" $value={metadata.weight_contribution_to_root} />
        </ContributionTypeBar>
      </ContributionDetail>
    </>
  );
};

export const BaseNode = ({
  node,
  isHighlighted,
  isSelected,
  hasChildren,
  isExpanded,
  isRoot,
  description,
  onClick,
}) => {
  const formatPercent = value => `${(value * 100).toFixed(1)}%`;
  const filterExtremeContribution = node => {
    // simulated data sometimes have bigger swing, and if denom is low, contribution at leaf node can be very high since it multiplies through all the itermediary nodes
    return Math.abs(node.contribution_to_root) < 2;
  };
  return (
    <Container $isHighlighted={isHighlighted} $isSelected={isSelected} onClick={onClick}>
      {hasChildren && <ExpandIcon>{isExpanded ? '−' : '+'}</ExpandIcon>}

      <ContentGrid $hasContribution={!!node.contribution_to_root}>
        <Header>{node.header || `${node.metric.toUpperCase()}`}</Header>

        <MetricWrapper>
          <ComparisonContainer>
            <ComparisonRow>
              <MetricValue>{formatValue(node.base_value + node.absolute_diff)}</MetricValue>
              <ChangeIndicator $isPositive={node.absolute_diff > 0}>
                <span>{node.absolute_diff > 0 ? '↑' : '↓'}</span>
                <span>
                  {['cvr', 'ctr'].includes(node.metric)
                    ? formatValue(node.absolute_diff)
                    : formatShortNumber(node.absolute_diff)}
                </span>
                <span>({formatPercent(node.relative_diff)})</span>
              </ChangeIndicator>
            </ComparisonRow>
            <ComparisonRow>
              <span>Prev:</span>
              <span>{formatValue(node.base_value)}</span>
            </ComparisonRow>
          </ComparisonContainer>
        </MetricWrapper>

        {node.contribution_to_root && filterExtremeContribution(node) && !isRoot && (
          <ContributionWrapper>
            <ContributionContent node={node} />
          </ContributionWrapper>
        )}

        {isHighlighted && description && (
          <div
            style={{
              gridArea: 'description',
              fontSize: 14,
              color: '#92400e',
            }}
          >
            <Divider />
            {description}
          </div>
        )}
      </ContentGrid>
    </Container>
  );
};

BaseNode.propTypes = {
  node: PropTypes.shape({
    header: PropTypes.string,
    metric: PropTypes.string.isRequired,
    base_value: PropTypes.number.isRequired,
    absolute_diff: PropTypes.number.isRequired,
    relative_diff: PropTypes.number.isRequired,
    contribution_to_root: PropTypes.number,
    contribution_metadata: PropTypes.shape({
      base_weight: PropTypes.number,
      current_weight: PropTypes.number,
      metric_contribution: PropTypes.number,
      weight_contribution: PropTypes.number,
      metric_contribution_to_root: PropTypes.number,
      weight_contribution_to_root: PropTypes.number,
    }),
  }).isRequired,
  isHighlighted: PropTypes.bool,
  isSelected: PropTypes.bool,
  hasChildren: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isRoot: PropTypes.bool,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TreeNode } from './TreeNode';
import { TreeConfig, countAllNodes } from '../useTree';
import { ReactComponent as ChevronLeftIcon } from '../../icons/chevron-left.svg';

const NonKeyNodesGroup = styled.div`
  color: #6b7280;
  padding: 8px 16px;
  margin: 0;
  margin-left: calc(
    ${props => props.$level * TreeConfig.levelIndent}px + ${TreeConfig.baseSpacing * 3}px
  );
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: #f9fafb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    background: white;
    border-color: #9ca3af;
    color: #374151;
    transform: translateX(4px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const ChevronIcon = styled(ChevronLeftIcon)`
  width: 14px;
  height: 14px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${props => (props.$isExpanded ? 'rotate(270deg)' : 'rotate(180deg)')};
  fill: currentColor;
  flex-shrink: 0;
`;

export const NonKeyNodes = ({
  nodes,
  level,
  keyNodes,
  expandedNodes,
  expandAllNodes,
  onNodeClick,
  selectedNodeId,
}) => {
  const [isExpanded, setIsExpanded] = useState(
    expandAllNodes ? true : nodes.some(node => expandedNodes.has(node.id))
  );

  useEffect(() => {
    setIsExpanded(expandAllNodes ? true : nodes.some(node => expandedNodes.has(node.id)));
  }, [expandAllNodes, nodes, expandedNodes]);

  const totalDescendants = nodes.reduce((sum, node) => {
    return sum + countAllNodes(node);
  }, 0);

  const formatDisplayText = () => {
    if (isExpanded) {
      return null;
    }

    const itemText = nodes.length === 1 ? 'item' : 'items';

    if (totalDescendants === nodes.length) {
      return `Show ${nodes.length} non-key ${itemText}`;
    }
    return `Show ${nodes.length} non-key ${itemText} (${totalDescendants} total)`;
  };

  return (
    <>
      <NonKeyNodesGroup $level={level} onClick={() => setIsExpanded(!isExpanded)}>
        <ChevronIcon $isExpanded={isExpanded} />
        <span>{formatDisplayText()}</span>
      </NonKeyNodesGroup>
      {isExpanded &&
        nodes.map(child => (
          <TreeNode
            key={child.id}
            node={child}
            level={level}
            keyNodes={keyNodes}
            expandedNodes={expandedNodes}
            expandAllNodes={expandAllNodes}
            onNodeClick={onNodeClick}
            selectedNodeId={selectedNodeId}
          />
        ))}
    </>
  );
};

import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { generateTimeSeed } from '../utils/seedGenerator';
import { Divider } from '../atoms/Divider';
import 'react-datepicker/dist/react-datepicker.css';

const BasicConfig = ({ config, setConfig }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSeedChange = value => {
    setConfig(prev => ({
      ...prev,
      seed: parseInt(value),
    }));
  };

  const handleDateChange = date => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setConfig(prev => ({
      ...prev,
      start_date: formattedDate,
    }));
  };

  const getSelectedDate = () => {
    if (!config.start_date) {
      return new Date('2024-01-01T12:00:00.000Z');
    }
    try {
      return new Date(`${config.start_date}T12:00:00.000Z`);
    } catch {
      return new Date('2024-01-01T12:00:00.000Z');
    }
  };

  return (
    <div className="card">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <h4>Basic Config</h4>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: '4px',
            transform: `rotate(${isExpanded ? 180 : 0}deg)`,
            transition: 'transform 0.3s ease',
          }}
        >
          ▼
        </button>
      </div>
      {isExpanded && (
        <>
          <Divider />
          <div className="form-section seed-section">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <h4>Seed</h4>
              <button
                className="button-primary"
                onClick={() => handleSeedChange(generateTimeSeed())}
              >
                Random
              </button>
            </div>
            <div className="seed-input">
              <input
                style={{ marginBottom: '4px' }}
                type="number"
                value={config.seed || 0}
                onChange={e => handleSeedChange(e.target.value)}
                min="0"
                max="9999"
              />
            </div>
          </div>
          <div className="date-duration-row">
            <div className="date-section">
              <h4>Start Date</h4>
              <DatePicker
                selected={getSelectedDate()}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                className="date-input"
                utcOffset={0}
                timeZone="UTC"
              />
            </div>
            <div className="duration-section">
              <h4 style={{ textWrap: 'nowrap' }}>Duration (days)</h4>
              <input
                className="date-input"
                type="number"
                value={config.days || 30}
                onChange={e =>
                  setConfig(prev => ({
                    ...prev,
                    days: parseInt(e.target.value),
                  }))
                }
                min="1"
                max="180"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BasicConfig;

import React, { useState, useEffect } from 'react';
import './ControllerStyles.css';
import BasicConfig from './BasicConfig';
import { Divider } from '../atoms/Divider';

const PlatformHub = ({ config, setConfig }) => {
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  const availablePlatforms = ['Meta', 'TikTok', 'AppLovin', 'Unity'];
  const patternOptions = ['trend', 'cyclical', 'spike', 'none'];
  const intensityLevels = ['low', 'medium', 'high'];

  const handleAddPlatform = platform => {
    setSelectedPlatform(platform);
    setShowConfigModal(true);
  };

  const handleConfigureSave = platformConfig => {
    setConfig(prev => {
      const { anomalies, ...platformConfigWithoutAnomalies } = platformConfig;

      const new_platform_config = {
        ...prev.platform_config,
        [selectedPlatform]: platformConfigWithoutAnomalies,
      };

      const platformsChanged =
        Object.keys(new_platform_config).length !== Object.keys(prev.platform_config).length;

      const filteredAnomalies = (prev.anomalies || []).filter(
        anomaly => anomaly.platform !== selectedPlatform
      );
      const newAnomalies = [...filteredAnomalies, ...(anomalies || [])];

      const platformCount = Object.keys(new_platform_config).length;
      const baseShare = Math.floor(100 / platformCount);
      const remainder = 100 - baseShare * platformCount;
      const distribution = Object.keys(new_platform_config).reduce(
        (acc, platform, index) => ({
          ...acc,
          [platform]: baseShare + (index < remainder ? 1 : 0),
        }),
        {}
      );

      return {
        ...prev,
        platform_config: new_platform_config,
        dimensions: platformsChanged ? {} : prev.dimensions,
        budget: platformsChanged ? { total_daily: 10000, distribution: distribution } : prev.budget,
        anomalies: newAnomalies,
      };
    });
    setShowConfigModal(false);
  };

  return (
    <div className="container">
      <BasicConfig config={config} setConfig={setConfig} />
      <Divider />
      {showConfigModal ? (
        <PlatformConfig
          platform={selectedPlatform}
          initialConfig={config.platform_config[selectedPlatform]}
          platformAnomalies={(config.anomalies || []).filter(
            anomaly => anomaly.platform === selectedPlatform
          )}
          startDate={config.start_date}
          onSave={handleConfigureSave}
          onClose={() => setShowConfigModal(false)}
          patternOptions={patternOptions}
          intensityLevels={intensityLevels}
        />
      ) : (
        <>
          <h3>Configured Platforms:</h3>
          {Object.keys(config.platform_config).length === 0 ? (
            <p className="text-secondary">No platforms configured yet</p>
          ) : (
            <ul className="config-row">
              {Object.entries(config.platform_config).map(([name, platformConfig]) => (
                <div
                  key={name}
                  className="card"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <div>
                    <h3 style={{ marginBottom: '8px' }}>{name}</h3>
                    <div className="config-details">
                      <div className="config-item">
                        {platformConfig.pattern.type !== 'none' ? (
                          <span>{`${platformConfig.pattern.type} (${platformConfig.pattern.severity})`}</span>
                        ) : (
                          <label>pattern: none</label>
                        )}
                      </div>
                      <div className="config-item">
                        <label>{platformConfig.volatility} </label>
                        <label>volatility</label>
                      </div>
                      <div className="config-item">
                        <label>{platformConfig.seasonality} </label>
                        <label>seasonality</label>
                      </div>
                      {(() => {
                        const anomalyCount = (config.anomalies || []).filter(
                          anomaly => anomaly.platform === name
                        ).length;

                        return anomalyCount > 0 ? (
                          <div className="config-item" style={{ color: 'red' }}>
                            <label>Anomalies: </label>
                            <label>{anomalyCount}</label>
                          </div>
                        ) : null;
                      })()}
                    </div>
                  </div>
                  <button
                    className="button button-secondary"
                    style={{ height: '36px' }}
                    onClick={() => handleAddPlatform(name)}
                  >
                    Edit
                  </button>
                </div>
              ))}
            </ul>
          )}

          <div>
            <h3>Add Platform</h3>
            <div className="config-buttons">
              {availablePlatforms.map(platform => {
                return (
                  <button
                    key={platform}
                    className={`button ${config.platform_config[platform] ? 'button-secondary' : 'button-primary'}`}
                    onClick={() => handleAddPlatform(platform)}
                    disabled={config.platform_config[platform]}
                  >
                    {platform}
                  </button>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const PlatformConfig = ({
  platform,
  initialConfig,
  platformAnomalies,
  startDate,
  onSave,
  onClose,
  patternOptions,
  intensityLevels,
}) => {
  const [config, setConfig] = useState(
    initialConfig || {
      pattern: {
        type: 'trend',
        severity: 'low_positive',
      },
      volatility: 'low',
      seasonality: 'low',
      anomalies: platformAnomalies || [],
    }
  );

  const [newAnomaly, setNewAnomaly] = useState({
    date: startDate || '',
    platform: platform,
    impact: 'high',
  });
  const [showAddAnomaly, setShowAddAnomaly] = useState(false);

  useEffect(() => {
    if (initialConfig) {
      setConfig(prev => ({
        ...initialConfig,
        anomalies: platformAnomalies || [],
      }));
    }
  }, [initialConfig, platformAnomalies, platform]);

  const handleAddAnomaly = () => {
    if (newAnomaly.date) {
      const dateExists = config.anomalies?.some(anomaly => anomaly.date === newAnomaly.date);

      if (!dateExists) {
        setConfig(prev => ({
          ...prev,
          anomalies: [...(prev.anomalies || []), { ...newAnomaly, platform }],
        }));
        setNewAnomaly({
          date: startDate,
          impact: 'low',
          platform,
        });
        setShowAddAnomaly(false);
      } else {
        alert('An anomaly already exists for this date');
      }
    }
  };

  const handleRemoveAnomaly = index => {
    setConfig(prev => ({
      ...prev,
      anomalies: prev.anomalies.filter((_, i) => i !== index),
    }));
  };

  const getSeverityOptions = patternType => {
    switch (patternType) {
      case 'trend':
        return ['low_positive', 'high_positive', 'low_negative', 'high_negative'];
      case 'cyclical':
        return ['low', 'high'];
      case 'spike':
        return ['low', 'high'];
      default:
        return [];
    }
  };

  const severityOptions = getSeverityOptions(config.pattern.type);

  return (
    <div className="modal">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <h3>Configure {platform}</h3>

        {showAddAnomaly ? (
          <div className="form-group">
            <h3>Anomaly Detail</h3>
            <div className="card">
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <h4>Date</h4>
                <div>
                  <input
                    type="date"
                    value={newAnomaly.date}
                    min={startDate}
                    onChange={e =>
                      setNewAnomaly(prev => ({
                        ...prev,
                        date: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <h4>Severity</h4>
                <div style={{ width: '50%' }}>
                  <select
                    value={newAnomaly.impact}
                    onChange={e =>
                      setNewAnomaly(prev => ({
                        ...prev,
                        impact: e.target.value,
                      }))
                    }
                  >
                    {['low', 'high'].map(level => (
                      <option key={level} value={level}>
                        {level}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={{ display: 'flex', gap: '4px', marginTop: '4px' }}>
                <button className="button-primary small" onClick={handleAddAnomaly}>
                  Add
                </button>
                <button className="button-secondary" onClick={() => setShowAddAnomaly(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="form-group">
            <h3>Scheduled Anomalies</h3>
            {config.anomalies && config.anomalies.length > 0 ? (
              <ul className="anomalies-list">
                {config.anomalies.map((anomaly, index) => (
                  <li key={index} className="anomaly-item">
                    <span>
                      {anomaly.date.split('-').join('/')} - Impact: {anomaly.impact}
                    </span>
                    <button
                      style={{ border: 'none', cursor: 'pointer' }}
                      onClick={() => handleRemoveAnomaly(index)}
                    >
                      ❌
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-secondary">No anomalies scheduled</p>
            )}
            <button className="button-secondary" onClick={() => setShowAddAnomaly(true)}>
              Add Anomaly
            </button>
          </div>
        )}

        <div className="form-section">
          <div className="form-group">
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <div style={{ width: '50%' }}>
                <h3>Pattern</h3>
                <select
                  value={config.pattern.type}
                  onChange={e => {
                    const patternType = e.target.value;
                    setConfig(prev => ({
                      ...prev,
                      pattern: {
                        type: patternType,
                        severity: getSeverityOptions(patternType)[0],
                      },
                    }));
                  }}
                >
                  {patternOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ width: '50%' }}>
                <h3>Severity</h3>
                <select
                  value={config.pattern.severity}
                  onChange={e =>
                    setConfig(prev => ({
                      ...prev,
                      pattern: {
                        ...prev.pattern,
                        severity: e.target.value,
                      },
                    }))
                  }
                >
                  {severityOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group">
            <h3>Volatility</h3>
            <select
              value={config.volatility}
              onChange={e => setConfig(prev => ({ ...prev, volatility: e.target.value }))}
            >
              {intensityLevels.map(level => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <h3>Seasonality</h3>
            <select
              value={config.seasonality}
              onChange={e => setConfig(prev => ({ ...prev, seasonality: e.target.value }))}
            >
              {intensityLevels.map(level => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="modal-actions">
          <button className="button button-primary" onClick={() => onSave(config)}>
            Save Configuration
          </button>
          <button className="button button-secondary" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlatformHub;


export const TreeConfig = {
  baseSpacing: 8,
  levelIndent: 40,
  nodeSpacing: 16,
  iconSize: 20,
  connectorWidth: 2,
};


export const formatValue = value => {
  if (typeof value === 'number') {
    const isPercent = Math.abs(value) < 1;
    const isLargeNumber = Math.abs(value) >= 1000;

    if (isPercent) return `${(value * 100).toFixed(2)}%`;
    if (isLargeNumber) return Math.round(value).toLocaleString();
    return value.toFixed(2);
  }
  return value;
};

export const countAllNodes = node => {
  if (!node.children) return 1;

  let count = 1;
  Object.values(node.children).forEach(children => {
    if (Array.isArray(children)) {
      children.forEach(child => {
        count += countAllNodes(child);
      });
    } else {
      Object.values(children).forEach(subChildren => {
        subChildren.forEach(child => {
          count += countAllNodes(child);
        });
      });
    }
  });
  return count;
};

import React, { useState } from 'react';
import PercentageEditorGroup from './atoms/PercentageEditorGroup';

const BudgetHub = ({ config, setConfig }) => {
  const [distribution, setDistribution] = useState(() => {
    if (config.budget?.distribution) {
      return config.budget.distribution;
    }
    if (Object.keys(config.platform_config).length === 1) {
      const platform = Object.keys(config.platform_config)[0];
      const initialDistribution = { [platform]: 100 };
      setConfig(prev => ({
        ...prev,
        budget: {
          ...prev.budget,
          distribution: initialDistribution,
        },
      }));
      return initialDistribution;
    }
    return {};
  });

  const [totalBudget, setTotalBudget] = useState(config.budget?.total_daily || 10000);
  const items = Object.keys(config.platform_config).map(platform => ({
    id: platform,
    name: platform,
  }));

  const [isDynamicAllocation, setIsDynamicAllocation] = useState(config.dynamic_allocation);
  const handleDynamicAllocationToggle = () => {
    const newValue = !isDynamicAllocation;
    setIsDynamicAllocation(newValue);
    setConfig(prev => ({
      ...prev,
      dynamic_allocation: newValue,
    }));
  };

  const handleBudgetChange = value => {
    const numValue = parseFloat(value);
    if (numValue >= 0) {
      setTotalBudget(numValue);
    }
  };

  const getPlatformDailyBudget = platform => {
    return totalBudget * (distribution[platform] / 100);
  };

  const handleDistributionSave = groupedDistribution => {
    const newDistribution = groupedDistribution.allocation;
    setDistribution(newDistribution);

    if (totalBudget > 0) {
      setConfig(prev => ({
        ...prev,
        budget: {
          total_daily: totalBudget,
          distribution: newDistribution,
        },
      }));
    }
  };

  return (
    <>
      <div style={{ marginBottom: '8px' }}>
        <div className="budget-row">
          <div className="form-section total-budget-section">
            <h4>Total Daily Budget</h4>
            <div className="budget-input">
              <span className="currency">$</span>
              <input
                type="number"
                value={totalBudget}
                onChange={e => handleBudgetChange(e.target.value)}
                min="0"
                step="100"
              />
            </div>
            {totalBudget <= 0 && (
              <span className="error-message">Budget must be greater than 0</span>
            )}
            <div className="form-group">
              <label className="toggle-label">
                <input
                  type="checkbox"
                  checked={isDynamicAllocation}
                  onChange={handleDynamicAllocationToggle}
                  className="toggle-input"
                />
                <span className="toggle-text">Dynamic Allocation</span>
              </label>
            </div>
          </div>
          <div className="form-section platform-distribution">
            <h4>Platform Budget Allocation</h4>
            <PercentageEditorGroup
              items={items}
              groupedByWeights={{ allocation: distribution }}
              onSave={handleDistributionSave}
              onCancel={() => {}}
              showLabel={false}
            />
          </div>

          <div>
            <h4>Budget Summary</h4>
            <div className="budget-row">
              <table className="budget-table">
                <thead>
                  <tr>
                    <th className="platform-col">Platform</th>
                    <th className="percentage-col">Share</th>
                    <th className="amount-col">Daily Budget</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map(({ id: platform }) => (
                    <tr key={platform}>
                      <td className="platform-col">{platform}</td>
                      <td className="percentage-col">
                        {(distribution[platform] ?? 0).toFixed(1)}%
                      </td>
                      <td className="amount-col">
                        ${(Number(getPlatformDailyBudget(platform)) || 0).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr className="total-row">
                    <td className="platform-col">
                      <strong>Total</strong>
                    </td>
                    <td className="percentage-col">100%</td>
                    <td className="amount-col">${totalBudget.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetHub;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import '../ControllerStyles.css';

const getEvenDistribution = items => {
  const evenShare = Math.floor(100 / items.length);
  const remainder = 100 % items.length;
  return items.reduce((acc, segment, index) => {
    acc[segment.id] = index === items.length - 1 ? evenShare + remainder : evenShare;
    return acc;
  }, {});
};

const PercentageEditor = ({ items, weights, onChange }) => {
  const prevValuesRef = useRef(null);
  const [currentValues, setCurrentValues] = useState(() => {
    if (weights && Object.keys(weights).length > 0) {
      return weights;
    }
    if (items.length === 1) {
      return { [items[0].id]: 100 };
    }
    return getEvenDistribution(items);
  });

  const [locks, setLocks] = useState(() => {
    return items.reduce((acc, segment) => {
      acc[segment.id] = false;
      return acc;
    }, {});
  });

  useEffect(() => {
    const valuesString = JSON.stringify(currentValues);
    if (prevValuesRef.current !== valuesString) {
      onChange(currentValues);
      prevValuesRef.current = valuesString;
    }
  }, [currentValues, onChange]);

  const handleLockToggle = segmentId => {
    setLocks(prev => ({
      ...prev,
      [segmentId]: !prev[segmentId],
    }));
  };

  const handlePercentageChange = useCallback(
    (segmentId, value) => {
      const numValue = parseInt(value, 10);
      if (isNaN(numValue) || numValue < 0 || numValue > 100) return;

      setCurrentValues(prev => {
        if (items.length === 1) {
          return { [segmentId]: 100 };
        }

        const lockedSum = Object.entries(prev)
          .filter(([sid]) => sid !== segmentId && locks[sid])
          .reduce((sum, [, value]) => sum + value, 0);

        const maxAllowed = 100 - lockedSum;
        if (numValue > maxAllowed) return prev;

        const newValues = { ...prev, [segmentId]: numValue };
        const unlockedIds = Object.keys(newValues).filter(sid => sid !== segmentId && !locks[sid]);

        if (unlockedIds.length === 0) return prev;

        const currentValue = prev[segmentId];
        const delta = numValue - currentValue;

        if (delta > 0) {
          const availableToReduce = unlockedIds.reduce((sum, sid) => {
            return sum + (newValues[sid] > 0 ? newValues[sid] : 0);
          }, 0);

          if (delta > availableToReduce) return prev;
        }

        newValues[segmentId] = numValue;
         const remainingShare = 100 - numValue - lockedSum;

         const currentUnlockedSum = unlockedIds.reduce(
           (sum, sid) => sum + (newValues[sid] > 0 ? newValues[sid] : 0),
           0
         );

        unlockedIds.forEach((sid, index) => {
          if (currentUnlockedSum > 0) {
            if (newValues[sid] > 0) {
              newValues[sid] = Math.round((newValues[sid] / currentUnlockedSum) * remainingShare);
            }
          } else {
            newValues[sid] = Math.floor(remainingShare / unlockedIds.length);
          }

          newValues[sid] = Math.max(0, newValues[sid]);

          if (index === unlockedIds.length - 1) {
            const totalSum = Object.values(newValues).reduce((sum, val) => sum + val, 0);
            if (totalSum !== 100) {
              newValues[sid] = Math.max(0, newValues[sid] + (100 - totalSum));
            }
          }
        });

        const finalSum = Object.values(newValues).reduce((sum, val) => sum + val, 0);
        if (finalSum !== 100 || Object.values(newValues).some(val => val < 0)) {
          return prev;
        }

        return newValues;
      });
    },
    [items.length, locks]
  );

  return (
    <div className={`segments-config`}>
      {items.map(item => (
        <div key={item.id} className="segment-row">
          <div className="segment-info">
            <span className="segment-name">{item.name}</span>
            <div className="segment-controls">
              {items.length > 1 && (
                <button
                  className={`lock-button ${locks[item.id] ? 'locked' : ''}`}
                  onClick={() => handleLockToggle(item.id)}
                >
                  {locks[item.id] ? '🔒' : '🔓'}
                </button>
              )}
              <div className="percentage-input">
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={currentValues[item.id]}
                  onChange={e => handlePercentageChange(item.id, e.target.value)}
                  disabled={locks[item.id] || items.length === 1}
                  className={locks[item.id] || items.length === 1 ? 'input-locked' : ''}
                />
                <span>%</span>
              </div>
            </div>
          </div>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{
                width: `${currentValues[item.id] ?? 0}%`,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export const PercentageEditorGroup = ({
  items,
  groupedByWeights,
  onSave,
  onCancel,
  showLabel = true,
}) => {
  const [allValues, setAllValues] = useState(() => groupedByWeights || {});
  const [isValid, setIsValid] = useState(true);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const handleSectionChange = useCallback((platform, values) => {
    setAllValues(prev => ({
      ...prev,
      [platform]: values,
    }));
  }, []);

  useEffect(() => {
    const isValidTotal = Object.values(allValues).every(sectionValues => {
      const sum = Object.values(sectionValues).reduce((acc, val) => acc + val, 0);
      const valuesAreValid = Object.values(sectionValues).every(
        value => !isNaN(value) && value >= 0 && value <= 100
      );
      return sum === 100 && valuesAreValid;
    });
    setIsValid(isValidTotal);
  }, [allValues]);

  return (
    <div>
      <div className="modal-actions">
        <button
          className={`button-primary ${!isValid ? 'disabled' : ''}`}
          onClick={() => {
            onSave(allValues);
            setFeedbackMessage('Succeed!');
          }}
          disabled={!isValid}
        >
          Save
        </button>
        <button
          className="button-secondary"
          onClick={() => {
            onCancel();
            setFeedbackMessage('');
          }}
        >
          Cancel
        </button>
        {feedbackMessage && (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <span style={{ color: 'var(--success-color)' }}>{feedbackMessage}</span>
          </div>
        )}
      </div>

      {Object.keys(allValues).map(key => (
        <div key={key} className="percentage-section">
          {showLabel && <h3>{key}</h3>}
          <PercentageEditor
            items={items}
            weights={allValues[key]}
            onChange={values => handleSectionChange(key, values)}
          />
        </div>
      ))}
    </div>
  );
};

export default PercentageEditorGroup;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TreeConfig } from '../useTree';
import { BaseNode } from './BaseNode';
import { Group } from './Group';

const NodeContainer = styled.div`
  --indent: ${props => props.$level * TreeConfig.levelIndent}px;
  margin-left: calc(var(--indent) + ${TreeConfig.baseSpacing * 3}px);
  margin-bottom: ${TreeConfig.nodeSpacing}px;
  position: relative;
  transition: all 0.2s ease;
`;

const Connector = styled.div`
  position: absolute;
  --connector-offset: calc(-${TreeConfig.levelIndent + TreeConfig.baseSpacing * 2}px);
  left: var(--connector-offset);
  top: calc(-${TreeConfig.nodeSpacing / 2}px);
  width: ${TreeConfig.levelIndent}px;
  height: ${TreeConfig.nodeSpacing * 2}px;
  border-left: ${TreeConfig.connectorWidth}px solid #e5e7eb;
  border-bottom: ${TreeConfig.connectorWidth}px solid #e5e7eb;
`;

const ChildrenContainer = styled.div`
  position: relative;
  transition: all 0.3s ease;
  height: ${props => (props.$isExpanded ? 'auto' : '0')};
  visibility: ${props => (props.$isExpanded ? 'visible' : 'hidden')};
  opacity: ${props => (props.$isExpanded ? '1' : '0')};

  & > * {
    position: relative;
  }
`;

export const TreeNode = ({
  node,
  level = 0,
  keyNodes = {},
  expandedNodes,
  expandAllNodes,
  onNodeClick,
  selectedNodeId,
  isRoot,
}) => {
  const [isExpanded, setIsExpanded] = useState(expandAllNodes ? true : expandedNodes.has(node.id));

  useEffect(() => {
    setIsExpanded(expandAllNodes ? true : expandedNodes.has(node.id));
  }, [expandAllNodes, node.id, expandedNodes]);

  const isHighlighted = Boolean(keyNodes[node.id]);
  const hasChildren = node.children && Object.keys(node.children).length > 0;
  const isSelected = selectedNodeId === node.id;

  const handleNodeClick = () => {
    if (hasChildren) {
      setIsExpanded(!isExpanded);
    }
    onNodeClick?.(node);
  };

  return (
    <div style={{ marginBottom: '12px' }}>
      <NodeContainer $level={level} $isExpanded={isExpanded}>
        {level > 0 && <Connector />}
        <BaseNode
          node={node}
          isHighlighted={isHighlighted}
          isSelected={isSelected}
          hasChildren={hasChildren}
          isExpanded={isExpanded}
          isRoot={isRoot}
          description={isHighlighted ? keyNodes[node.id].highlight_reason : null}
          onClick={handleNodeClick}
        />
      </NodeContainer>
      {hasChildren && (
        <ChildrenContainer $isExpanded={isExpanded}>
          <Group
            childGroups={node.children}
            splitHeaders={node.split_key_headers}
            level={level}
            keyNodes={keyNodes}
            expandedNodes={expandedNodes}
            expandAllNodes={expandAllNodes}
            onNodeClick={onNodeClick}
            selectedNodeId={selectedNodeId}
          />
        </ChildrenContainer>
      )}
    </div>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import 'github-markdown-css/github-markdown.css';
import { mockData } from '../utils/mockData';
import Tree from '../tree/Tree';
import { Spinner } from '../atoms/Spinner';
import { BASE_URL } from '../utils/constants';

const USE_MOCK_DATA = false;

const MOCK_DELAYS = {
  analysis: 30,
  report: 30,
};

const getBaseDate = currentDate => {
  return new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 7))
    .toISOString()
    .split('T')[0];
};

const MarkdownReport = styled.div`
  &.markdown-body {
    background: transparent;
    font-family: system-ui;
    padding: 0;
    font-size: 0.9em;
    line-height: 1.4;

    h2 {
      font-size: 1.4em;
      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    h3 {
      font-size: 1.2em;
      margin-top: 0.8em;
      margin-bottom: 0.4em;
    }

    p {
      margin: 0.5em 0;
    }

    ul {
      list-style: disc;
      padding-left: 1em;
      margin: 0.5em 0;
    }

    li + li {
      margin-top: 0.2em;
    }
  }
`;

class AnalysisService {
  constructor() {
    this.cache = new Map();
    this.pendingRequests = new Map();
  }

  async fetchWithCache(type, key, fetcher) {
    const cacheKey = `${type}-${key}`;

    if (this.cache.has(cacheKey)) {
      USE_MOCK_DATA && console.log(key, type, 'found in cache');
      return this.cache.get(cacheKey);
    }

    if (this.pendingRequests.has(cacheKey)) {
      return this.pendingRequests.get(cacheKey);
    }

    try {
      const controller = new AbortController();
      const promise = fetcher(controller.signal)
        .then(result => {
          this.cache.set(cacheKey, result);
          return result;
        })
        .finally(() => {
          this.pendingRequests.delete(cacheKey);
        });

      this.pendingRequests.set(cacheKey, promise);
      return promise;
    } catch (error) {
      this.pendingRequests.delete(cacheKey);
      throw error;
    }
  }

  getAnalysis(key) {
    return this.fetchWithCache('analysis', key, async signal => {
      if (USE_MOCK_DATA) {
        console.log('start fetching analysis for', key);
        return new Promise((resolve, reject) => {
          const timer = setTimeout(() => {
            console.log('finished fetching analysis for', key);
            resolve(mockData.fetch_metric_tree);
          }, MOCK_DELAYS.analysis);

          signal.addEventListener('abort', () => {
            clearTimeout(timer);
            reject(new DOMException('Aborted', 'AbortError'));
          });
        });
      }

      const { metric, date: current_date } = JSON.parse(key);
      return this.makeRequest(
        'fetch_metric_tree',
        {
          metric: metric.toLowerCase(),
          base_date: getBaseDate(current_date),
          current_date,
        },
        signal
      );
    });
  }

  getReport(key) {
    return this.fetchWithCache('report', key, async signal => {
      if (USE_MOCK_DATA) {
        console.log('start fetching report for', key);
        return new Promise((resolve, reject) => {
          const timer = setTimeout(() => {
            console.log('finished fetching report for', key);
            resolve(mockData.fetch_metric_report);
          }, MOCK_DELAYS.report);

          signal.addEventListener('abort', () => {
            clearTimeout(timer);
            reject(new DOMException('Aborted', 'AbortError'));
          });
        });
      }

      const { metric, date: current_date } = JSON.parse(key);
      return this.makeRequest(
        'fetch_metric_report',
        {
          metric: metric.toLowerCase(),
          base_date: getBaseDate(current_date),
          current_date,
        },
        signal
      );
    });
  }

  async makeRequest(endpoint, body, signal) {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Origin: 'http://localhost:3000',
      },
      credentials: 'include',
      mode: 'cors',
      signal,
      body: JSON.stringify(body),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(`API ${response.status}: ${responseData.error || 'Unknown error'}`);
      error.status = response.status;
      error.details = responseData.error;
      throw error;
    }

    return responseData;
  }

  clearState() {
    this.pendingRequests.forEach((_, key) => {
      const [type] = key.split('-');
    });
    this.cache.clear();
    this.pendingRequests.clear();
  }
}

export const analysisService = new AnalysisService();

const ResultView = ({ anomaly, analysis, report, activeView, onViewChange }) => {
  const ViewToggleButton = ({ active, error, loading, onClick, children }) => {
    const getStateIndicator = () => {
      if (loading) return <Spinner style={{ marginLeft: '4px' }} />;
      if (error) return <span style={{ marginLeft: '4px' }}>⚠️</span>;
      return null;
    };

    return (
      <button
        onClick={onClick}
        disabled={loading}
        style={{
          ...styles.viewToggleButton,
          ...(active && styles.viewToggleButtonActive),
          ...(loading && styles.viewToggleButtonLoading),
          ...(error && styles.viewToggleButtonError),
        }}
      >
        <div style={styles.loadingWrapper}>
          {children}
          {getStateIndicator()}
        </div>
      </button>
    );
  };

  const ContentView = ({
    activeView,
    analysis: { data: analysisData, loading: isAnalysisLoading, error: analysisError },
    report: { data: reportData, loading: isReportLoading, error: reportError },
    keyNodes,
  }) => {
    const renderError = error => (
      <div style={styles.error}>
        <div style={styles.errorTitle}>{error.split(':')[0]}</div>
        <div style={styles.errorDetails}>{error.split(':').slice(1).join(':')}</div>
      </div>
    );

    if (activeView === 'tree') {
      if (isAnalysisLoading) return <div>Loading analysis...</div>;
      if (analysisError) return renderError(analysisError);
      if (!analysisData) return null;

      return (
        <div style={styles.result}>
          <Tree tree={analysisData} keyNodes={keyNodes} loading={isReportLoading} />
        </div>
      );
    }

    if (isReportLoading) return <div>Loading report...</div>;
    if (reportError) return renderError(reportError);
    if (!reportData) return null;

    return (
      <div style={styles.reportSection}>
        <MarkdownReport className="markdown-body">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              h1: ({ node, ...props }) => <h2 {...props} />,
              h2: ({ node, ...props }) => <h3 {...props} />,
            }}
          >
            {reportData.report}
          </ReactMarkdown>
        </MarkdownReport>
      </div>
    );
  };

  const keyNodes = report.data?.key_nodes || {};
  return (
    <div style={styles.resultContainer}>
      <div style={styles.header}>
        <h4>
          {anomaly.metric}: {anomaly.date} vs {getBaseDate(anomaly.date)}
        </h4>

        <div style={styles.viewToggle}>
          <ViewToggleButton
            active={activeView === 'tree'}
            error={analysis.error}
            loading={analysis.loading}
            onClick={() => onViewChange('tree')}
          >
            Tree View
          </ViewToggleButton>
          <ViewToggleButton
            active={activeView === 'report'}
            error={report.error}
            loading={report.loading}
            onClick={() => onViewChange('report')}
          >
            Report View
          </ViewToggleButton>
        </div>
      </div>
      <ContentView
        activeView={activeView}
        analysis={analysis}
        report={report}
        keyNodes={keyNodes}
      />
    </div>
  );
};

const useAnalysisState = initialKey => {
  const [state, setState] = useState({
    localSelectedKey: initialKey,
    results: {},
    isLoading: {},
    errors: {},
    viewStates: {},
  });

  const resetState = useCallback(() => {
    setState({
      localSelectedKey: null,
      results: {},
      isLoading: {},
      errors: {},
      viewStates: {},
    });
    analysisService.clearState();
  }, []);

  const setLocalSelectedKey = useCallback(key => {
    setState(prev => ({ ...prev, localSelectedKey: key }));
  }, []);

  const setViewState = useCallback((key, showTree) => {
    setState(prev => ({
      ...prev,
      viewStates: { ...prev.viewStates, [key]: showTree },
    }));
  }, []);

  const fetchResults = useCallback(async (key, anomaly) => {
    if (!key || !anomaly) return;

    setState(prev => ({
      ...prev,
      isLoading: {
        ...prev.isLoading,
        [key]: { analysis: true, report: true },
      },
      errors: {
        ...prev.errors,
        [key]: null,
      },
    }));

    analysisService
      .getAnalysis(key)
      .then(analysis => {
        setState(prev => ({
          ...prev,
          results: {
            ...prev.results,
            [key]: { ...prev.results[key], analysis },
          },
          isLoading: {
            ...prev.isLoading,
            [key]: { ...prev.isLoading[key], analysis: false },
          },
        }));
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          setState(prev => ({
            ...prev,
            errors: {
              ...prev.errors,
              [key]: { ...prev.errors[key], analysis: `Analysis Error: ${error.message}` },
            },
            isLoading: {
              ...prev.isLoading,
              [key]: { ...prev.isLoading[key], analysis: false },
            },
          }));
        }
      });

    analysisService
      .getReport(key)
      .then(report => {
        setState(prev => ({
          ...prev,
          results: {
            ...prev.results,
            [key]: { ...prev.results[key], report },
          },
          isLoading: {
            ...prev.isLoading,
            [key]: { ...prev.isLoading[key], report: false },
          },
        }));
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          setState(prev => ({
            ...prev,
            errors: {
              ...prev.errors,
              [key]: { ...prev.errors[key], report: `Report Error: ${error.message}` },
            },
            isLoading: {
              ...prev.isLoading,
              [key]: { ...prev.isLoading[key], report: false },
            },
          }));
        }
      });
  }, []);

  return {
    ...state,
    setLocalSelectedKey,
    setViewState,
    resetState,
    fetchResults,
  };
};

export const Analysis = ({ anomaliesToAnalyze, clickedAnomalyKey }) => {
  const {
    localSelectedKey,
    setLocalSelectedKey,
    results,
    isLoading,
    errors,
    viewStates,
    setViewState,
    resetState,
    fetchResults,
  } = useAnalysisState(clickedAnomalyKey);

  useEffect(() => {
    if (Object.keys(anomaliesToAnalyze).length === 0) {
      resetState();
    }
  }, [anomaliesToAnalyze, resetState]);

  useEffect(() => {
    setLocalSelectedKey(clickedAnomalyKey);
  }, [clickedAnomalyKey]);

  useEffect(() => {
    if (!localSelectedKey || !anomaliesToAnalyze[localSelectedKey]) return;

    fetchResults(localSelectedKey, anomaliesToAnalyze[localSelectedKey]);
  }, [localSelectedKey, anomaliesToAnalyze, fetchResults]);

  const renderContent = useCallback(
    key => {
      if (!anomaliesToAnalyze[key]) return null;

      const currentResults = results[key] || {};
      const currentLoading = isLoading[key] || {};
      const currentErrors = errors[key] || {};

      return (
        <ResultView
          anomaly={anomaliesToAnalyze[key]}
          analysis={{
            data: currentResults.analysis,
            loading: currentLoading.analysis,
            error: currentErrors.analysis,
          }}
          report={{
            data: currentResults.report,
            loading: currentLoading.report,
            error: currentErrors.report,
          }}
          activeView={(viewStates[key] ?? true) ? 'tree' : 'report'}
          onViewChange={view => setViewState(key, view === 'tree')}
        />
      );
    },
    [results, isLoading, errors, anomaliesToAnalyze, viewStates, setViewState]
  );

  return (
    <div style={styles.container}>
      <h3>Selected Analysis</h3>
      <select
        value={localSelectedKey || ''}
        onChange={e => setLocalSelectedKey(e.target.value)}
        style={styles.select}
      >
        <option value="">Select an analysis...</option>
        {Object.entries(anomaliesToAnalyze).map(([key, anomaly]) => (
          <option key={key} value={key}>
            {anomaly.date}: {anomaly.metric}
          </option>
        ))}
      </select>
      {localSelectedKey && renderContent(localSelectedKey)}
    </div>
  );
};

const styles = {
  container: {
    marginTop: '20px',
    padding: '12px',
  },
  select: {
    width: '100%',
    padding: '8px',
    marginTop: '8px',
    borderRadius: '4px',
  },
  resultContainer: {
    marginTop: '16px',
    padding: '12px',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px',
  },
  result: {
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
  },
  reportSection: {
    paddingTop: '16px',
  },
  reportButton: {
    padding: '8px 16px',
    backgroundColor: '#1a73e8',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewToggle: {
    display: 'flex',
    width: '240px',
    backgroundColor: '#f0f0f0',
    padding: '4px',
    borderRadius: '4px',
  },
  viewToggleButton: {
    flex: 1,
    padding: '8px 0',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    borderRadius: '2px',
    fontSize: '14px',
    color: '#666',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewToggleButtonActive: {
    backgroundColor: 'white',
    color: '#1a73e8',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  },
  viewToggleButtonLoading: {
    backgroundColor: '#f5f5f5',
    cursor: 'not-allowed',
    color: '#999',
  },
  viewToggleButtonError: {
    borderColor: '#dc2626',
    color: '#dc2626',
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    fontSize: '12px',
    color: '#666',
  },
  spinner: {
    width: '12px',
    height: '12px',
    border: '2px solid #1a73e8',
    borderTop: '2px solid transparent',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
  },
  error: {
    marginTop: '16px',
    padding: '12px',
    backgroundColor: '#fde8e8',
    color: '#dc2626',
    borderRadius: '4px',
    fontSize: '14px',
  },
  errorTitle: {
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  errorDetails: {
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
    fontSize: '12px',
    marginTop: '8px',
    padding: '8px',
    background: 'rgba(0,0,0,0.05)',
    borderRadius: '4px',
  },
};
